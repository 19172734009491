<template>
  <div
    class="blocks-colored-info"
    :class="[`blocks-colored-info--theme--${theme}`]"
    @click="emitComponentClick"
  >
    <slot />

    <button
      v-if="withButton"
      class="blocks-colored-info__button"
      @click="emitClick"
    >
      <IconsArrowSm class="blocks-colored-info__arrow" />
    </button>
  </div>
</template>

<script>
import IconsArrowSm from "@/components/icons/IconsArrowSm.vue";

export default {
  name: "BlocksColoredInfo",
  components: { IconsArrowSm },
  emits: ["click", "componentClick"],
  props: {
    theme: {
      type: String,
      default: "blue",
      validator(value) {
        return ["blue", "green", "yellow"].includes(value);
      },
    },
    withButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
    emitComponentClick() {
      this.$emit("componentClick");
    },
  },
};
</script>

<style scoped lang="scss">
.blocks-colored-info {
  position: relative;
  $parent: &;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &__button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
    top: 0;
    right: 0;
    border: none;
    cursor: pointer;
  }

  &__arrow {
    width: 14px;
    height: 14px;
  }

  &--theme {
    &--blue {
      background-color: $color-light-purple;
      color: $color-blue;

      #{$parent} {
        &__button {
          background-color: $color-blue;
        }

        &__arrow {
          color: $color-light-purple;
        }
      }
    }

    &--green {
      background-color: $color-light-green;
      color: $color-dark-green;

      #{$parent} {
        &__button {
          background-color: $color-dark-green;
        }

        &__arrow {
          color: $color-light-green;
        }
      }
    }

    &--yellow {
      background-color: $color-light-yellow;
      color: $color-dark-yellow;

      #{$parent} {
        &__button {
          background-color: $color-dark-yellow;
        }

        &__arrow {
          color: $color-light-yellow;
        }
      }
    }
  }
}
</style>
