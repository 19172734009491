import { haveLeaderReward } from "@/utils/helpers/leader";
import rewardTypes from "@/constants/rewardTypes";

const getSum = (generators) => {
  return generators.reduce((sum, generator) => {
    if (generator.item.leader_value >= 10000 || !generator.haveReward) {
      return sum + generator.item.leader_value;
    } else {
      return sum + 10000;
    }
  }, 0);
};

const fullReward = (generators, bo) => {
  if (!generators.length) return;

  return getSum(generators) * bo * 0.06;
};

const partReward = (generators, bo, points) => {
  if (!generators.length) return;

  return getSum(generators) * bo * 0.06 - (10000 - points) * bo * 0.06;
};
export default (tree, bo) => {
  const reward = haveLeaderReward(tree);

  switch (reward.type) {
    case rewardTypes.FULL:
      return fullReward(reward.generators, bo);

    case rewardTypes.PART:
      return partReward(reward.generators, bo, tree.group_points);

    default:
      return 0;
  }
};
