import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import { stateSymbol, createState } from "./App.store.js";

import MoveHelper from "./plugins/MoveHelper.js";
import popup from "@/plugins/popup";
import mitt from "mitt";
import scrollLockClient from "@/plugins/scroll-lock.client";

// Получаем значение locale из localStorage, если оно там есть, иначе используем 'ru'
const locale = localStorage.getItem("locale") || "ru";

// eslint-disable-next-line
const app = createApp(App);
app.config.globalProperties.$emitter = mitt();
app.provide(
  stateSymbol,
  createState({
    locale: locale,
  })
);
app.use(MoveHelper);
app.use(router);
app.use(scrollLockClient);
app.use(popup);
app.mount("#app");
