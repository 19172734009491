import ru from "./ru.js";
import kz from "./kz.js";
import mn from "./mn.js";
import ky from "./ky.js";

export default {
  ru,
  kz,
  mn,
  ky,
};
