<template>
  <div class="blocks-products-summary">
    <div class="blocks-products-summary__products">
      <span
        class="blocks-products-summary__font blocks-products-summary__font--name"
      >
        {{ state.app.locale.redirectPage.products }} </span
      >&nbsp;

      <span
        class="blocks-products-summary__font blocks-products-summary__font--value"
      >
        {{ productsCount }}
      </span>
    </div>

    <div class="blocks-products-summary__price">
      <span
        class="blocks-products-summary__font blocks-products-summary__font--name"
      >
        {{ state.app.locale.redirectPage.total }} </span
      >&nbsp;

      <span
        class="blocks-products-summary__font blocks-products-summary__font--value"
      >
        {{ totalPrice }}
      </span>
    </div>
  </div>
</template>

<script>
import { useState } from "@/App.store";
export default {
  name: "BlocksProductsSummary",
  props: {
    productsCount: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const state = useState();

    return {
      state,
    };
  },
};
</script>

<style scoped lang="scss">
.blocks-products-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-light-gray-2;
  border-radius: 8px;
  padding: 8px 0;
  color: $color-dark-gray;

  &__products {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }

  &__price {
    display: flex;
    align-items: center;
  }

  &__font {
    &--name {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 24px;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &--value {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>
