<template>
  <button type="button" class="ui-select-button">
    <slot />
  </button>
</template>

<script>
export default {
  name: "UiSelectButton",
};
</script>

<style scoped lang="scss">
.ui-select-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $color-light-gray;
  color: $color-dark;
  background-color: $color-white;
  cursor: pointer;
}
</style>
