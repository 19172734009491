<template>
  <svg
    viewBox="0 0 8 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.60156 6.14844C7.95703 6.47656 7.95703 7.05078 7.60156 7.37891L2.35156 12.6289C2.02344 12.9844 1.44922 12.9844 1.12109 12.6289C0.765625 12.3008 0.765625 11.7266 1.12109 11.3984L5.74219 6.75L1.12109 2.12891C0.765625 1.80078 0.765625 1.22656 1.12109 0.898438C1.44922 0.542969 2.02344 0.542969 2.35156 0.898438L7.60156 6.14844Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconsArrowSm",
};
</script>
