<template>
  <!-- wrapper for v-for -->
  <div v-if="hasItems">
    <div
      v-for="(item, index) in itemsWithData"
      :key="item.id"
      class="layout-popup"
      :class="[`layout-popup--${item.name}`, item.classes]"
      style="position: absolute; width: 100%; height: 100%"
      :style="{ zIndex: index }"
    >
      <transition name="transition" appear>
        <div v-if="item.visible" class="layout-popup__background"></div>
      </transition>

      <div
        ref="wrapper"
        class="layout-popup__wrapper layout-popup__wrapper--main"
        tabindex="-1"
        @pointerup="setComponentPointerDown(false)"
        @click="hideItemByWrapperClick(item.id)"
        @keyup.esc="hideItem({ id: item.id })"
      >
        <div class="layout-popup__wrapper layout-popup__wrapper--component">
          <transition
            name="transition"
            appear
            @after-leave="removeItemById(item.id)"
          >
            <component
              :is="`${item.name}-popup`"
              v-if="item.visible"
              class="layout-popup__component"
              v-bind="item.data"
              @pointerdown="setComponentPointerDown(true)"
              @click.stop
              @close="hideItem({ id: item.id })"
              @switch="handleSwitch"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const types = [
  // {
  //   popups: [''],
  //   modifier: '',
  // },
];

// prefetch popups by open any
const prefetchGroup = [[]];

export default {
  components: {
    "info-popup": defineAsyncComponent(() =>
      import("@/components/popups/InfoPopup.vue")
    ),
    "options-popup": defineAsyncComponent(() =>
      import("@/components/popups/OptionsPopup.vue")
    ),
    "warning-popup": defineAsyncComponent(() =>
      import("@/components/popups/WarningPopup.vue")
    ),
  },
  setup() {
    return {
      lastId: 0,
      componentPointerDown: false,
    };
  },
  data() {
    return {
      items: [],
      visible: [],
    };
  },
  computed: {
    itemsWithData() {
      return this.items.map((item) => ({
        ...item,
        classes: this.getItemTypeClasses(item.name),
      }));
    },
    hasItems() {
      return !!this.items.length;
    },
  },
  watch: {
    items: {
      deep: true,
      handler(items) {
        if (!items.length) return;

        const item = items[items.length - 1].name;

        // prefetch popups
        prefetchGroup.forEach((group) => {
          if (!group.includes(item)) return;

          group.forEach((groupItem) => {
            const component = this.$options.components[`${groupItem}-popup`];
            if (!component) return;

            component.__asyncLoader();
          });
        });
      },
    },
  },
  created() {
    this.$popup.onOpen(this.showItem);
    this.$popup.onClose(this.hideItem);
    this.$popup.onCloseAll(this.hideAllItems);
  },
  beforeUnmount() {
    this.$popup.offOpen(this.showItem);
    this.$popup.offClose(this.hideItem);
    this.$popup.offCloseAll(this.hideAllItems);
  },
  methods: {
    async showItem({ name, data, doneCallback }) {
      const id = this.lastId++;

      this.items.push({
        id, // unique key

        name,
        data,

        visible: true, // for transitions
      });

      await this.$nextTick();

      // after actions
      const indexActual = this.findItemIndexById(id);
      const wrapper = this.$refs.wrapper[indexActual];

      this.$scrollLock.lock(wrapper);
      wrapper.focus();

      doneCallback?.(id);
    },
    async switchItem({ name, data, doneCallback }) {
      await this.hideItem();

      await this.showItem({ name, data, doneCallback });
    },
    async hideItem({ id, doneCallback } = {}) {
      const index = id ? this.findItemIndexById(id) : this.items.length - 1;
      if (index === -1) return;

      const item = this.items[index];
      item.visible = false;

      const removePromise = new Promise((resolve) => {
        item.removeCallback = resolve; // resolve hide promise
      });
      await removePromise;

      // after actions
      const indexActual = this.findItemIndexById(id);

      const wrapper = this.$refs.wrapper[indexActual];
      this.$scrollLock.unlock(wrapper);

      const indexLatest = this.items.length - 1;
      if (indexLatest === -1) return;

      const latestWrapper = this.$refs.wrapper[indexLatest];
      latestWrapper.focus();

      doneCallback?.();
    },
    hideAllItems() {
      this.items.forEach(({ id }) => {
        this.hideItem({ id });
      });
    },

    handleSwitch(name, data) {
      this.switchItem({ name, data });
    },

    // prevent closing by content selection
    hideItemByWrapperClick(id) {
      if (this.componentPointerDown) return;

      this.hideItem({ id });
    },
    async setComponentPointerDown(value) {
      if (!value) {
        await new Promise((resolve) => setTimeout(resolve));
      }

      this.componentPointerDown = value;
    },

    findItemIndexById(id) {
      if (typeof id !== "number") return -1;

      return this.items.findIndex((item) => item.id === id);
    },
    removeItemById(id) {
      const index = this.findItemIndexById(id);

      const { removeCallback } = this.items[index];
      this.items = this.items.filter((item, itemIndex) => itemIndex !== index);

      removeCallback?.();
    },

    getItemTypeClasses(name) {
      return types.reduce((classesString, { popups, modifier }) => {
        if (!popups.includes(name)) return classesString;

        classesString += ` layout-popup--type--${modifier}`;
        return classesString;
      }, "");
    },
  },
};
</script>

<style lang="scss" scoped>

@import url('/fonts/GTWalsheimPro/fontGTWalsheimPro.css');
.app__popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 300;
}
.layout-popup {
  position: relative;

  $parent: &;

  &__background {
    background-color: rgba(#000, 0.45);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    &--main {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      display: flex;
      outline: none;
    }

    &--component {
      margin: auto;
      display: flex;
      padding: 20px;
      width: 383px;
      position: relative;
    }
  }

  &__component {
    width: 100%;
    //will-change: transform;
  }
}
</style>
