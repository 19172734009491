<template>
  <div
    :class="[
      'edit-vertex-popup',
      {
        child: !vertex.root,
      },
    ]"
    ref="popup"
  >
    <div class="header" :class="`percent-${percentState || 0}`">
      <div class="percent">
        {{ vertex.level }}
        <span class="symbol">%</span>
      </div>
      <div class="g_points" v-if="vertex.root">
        {{ vertex.group_points }}
      </div>
      <div
        class="g_p_title"
        v-if="vertex.root"
        v-html="app.locale.group_points_title_vertexEdit"
      ></div>
      <div class="client-type" v-if="!vertex.root">
        {{
          app.calculator.clientType == "ABO"
            ? app.locale.lang_npa
            : app.locale.lang_vip
        }}
      </div>

      <svg class="bg" width="100%" height="100%">
        <rect
          class="headerBG"
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="#aaa"
          :clip-path="
            app.breakpoint.mobile
              ? `url(#edit-header-mobile)`
              : `url(#edit-header)`
          "
        />
        <!-- <use clip-path="url(#edit-header)" xlink:href="#headerBG" fill="red" /> -->
      </svg>
    </div>

    <div class="vertex-name" v-if="vertex.root">
      <Input
        v-if="editingName"
        class="name"
        type="text"
        :value="vertex.name ? vertex.name : app.locale.enter_name"
        :withClear="true"
        @change="update('name', $event)"
      />
      <div v-if="!editingName" class="name" @click="startEdit">
        {{ vertex.name ? vertex.name : app.locale.enter_name }}
      </div>

      <Button
        class="shape"
        icon="shape"
        icon-position="center"
        icon-color="#034580"
        :icon-size="app.breakpoint.mobile ? ['12px', '12px'] : ['16px', '16px']"
        @click="startEdit"
      />
    </div>

    <div
      :class="[
        {
          root: vertex.root,
        },
        'personal-points',
      ]"
    >
      <Input
        :class="[
          {
            'current-tutorial': app.tutorialStep == 'vertex-edit-1',
          },
          `PPV`,
        ]"
        :label="app.locale.inputs.personal_points"
        type="number"
        :value="vertex.personal_value"
        @change="
          update('personal_value', $event);
          changeTutorialStep('vertex-edit-2');
        "
        :tutorial="
          app.tutorialStep == 'vertex-edit-1'
            ? {
                title: app.locale.tutorial['vertex-edit-title'],
                text: app.locale.tutorial['vertex-edit-1-text'],
              }
            : null
        "
      />
      <Input
        :class="[
          {
            'current-tutorial': app.tutorialStep == 'vertex-edit-2',
          },
          `CV`,
        ]"
        v-if="vertex.root"
        :label="app.locale.inputs.client_value"
        type="number"
        :value="vertex.client_value"
        @change="update('client_value', $event)"
        :tutorial="
          app.tutorialStep == 'vertex-edit-2'
            ? {
                title: app.locale.tutorial['vertex-edit-title'],
                text: app.locale.tutorial['vertex-edit-2-text'],
                align: 'right',
              }
            : null
        "
      />

      <!--      <Checkbox v-if="!vertex.root"-->
      <!--        class="guardianship"-->
      <!--        @change="update('flag_guardianship', $event)"-->
      <!--        :checked="vertex.flag_guardianship"-->
      <!--        :label="vertex.root ? app.locale.checkboxes.guardianship : app.locale.checkboxes.guardianship_branch"-->
      <!--      />-->
      <Checkbox
        v-if="vertex.root && !vertex.hideBronze"
        class="bronze-start"
        @change="update('flag_bronze_start', $event)"
        :checked="vertex.flag_bronze_start"
        :label="app.locale.checkboxes.bronze_start"
      />
      <Checkbox
        v-if="vertex.root && !vertex.hideBronze"
        class="bronze-bonus"
        @change="update('flag_bronze_bonus', $event)"
        :checked="vertex.flag_bronze_bonus"
        :label="app.locale.checkboxes.bronze_bonus"
      />
    </div>

    <div class="total" v-if="vertex.root">
      <span class="label">{{ app.locale.total }}</span>
      <span class="cost"> {{ formatCurrency(app.calculator.total) }} </span>
    </div>
    <div class="accordions" :class="app.tutorialStep" v-if="vertex.root">
      <div class="personal-sales">
        <span class="personal-sales__label">
          {{ additionalBlockData.label }}
        </span>
        <span class="personal-sales__value">
          {{ formatCurrency(additionalBlockData.value) }}
        </span>
      </div>
      <Accordion
        :expand-block="
          app.tutorialStep == 'accordion-expand-helper' && amway_plan_expanded
        "
        @expand="expandHandler($event, 'amway_plan')"
        class="amway-plan"
        :list="amwayPlanList"
        :tutorial="
          app.tutorialStep == 'accordion-expand-helper'
            ? {
                title: app.locale.tutorial['accordion-expand-helper-title'],
                text: app.locale.tutorial['accordion-expand-helper-text'],
              }
            : app.tutorialStep == 'accordion-collapse-helper'
            ? {
                title: app.locale.tutorial['accordion-collapse-helper-title'],
                text: app.locale.tutorial['accordion-collapse-helper-text'],
              }
            : null
        "
      />
      <Accordion
        v-if="vertex.businessNature !== 'VIP'"
        :expand-block="
          app.tutorialStep == 'accordion-expand-helper' &&
          business_plus_expanded
        "
        @expand="expandHandler($event, 'business_plus')"
        class="business-plus"
        :list="businessPlusList"
      />
    </div>
  </div>
</template>
<script>
import { Button, Input, Checkbox, Accordion } from "@components/UI";
import { useState } from "@src/App.store.js";
import "./Edit-vertex.scss";
import formatCurrency from "../../utils/helpers/formatCurrency";

export default {
  name: "EditPopup",
  components: {
    Button,
    Input,
    Checkbox,
    Accordion,
  },
  props: {
    vertex: {
      required: true,
    },
    percentState: {
      default: 0,
    },
  },
  data() {
    return {
      editingName: false,
      expanded: 0,
      amway_plan_expanded: false,
      business_plus_expanded: false,
    };
  },
  setup() {
    return useState();
  },
  computed: {
    additionalBlockData() {
      return this.Tree.personal_sells_amount !== undefined
        ? this.personalSales
        : this.personalBuys;
    },
    personalBuys() {
      return {
        label: this.app.locale.listTitles.personal_friends_buy,
        value: this.Tree.personal_friends_buy,
      };
    },
    personalSales() {
      return {
        label: this.app.locale.listTitles.personal_sells_amount,
        value: this.Tree.personal_sells_amount,
      };
    },
    amwayPlanList() {
      let out = [];
      let total = 0;

      for (let key in this.Tree.amway_plan) {
        out.push({
          label: this.app.locale.listTitles[key],
          value: Math.floor(this.Tree.amway_plan[key])
            ? formatCurrency(this.Tree.amway_plan[key])
            : "-",
        });
        total += this.Tree.amway_plan[key];
      }

      out.push({
        label:
          this.Tree.businessNature === "VIP"
            ? this.app.locale.listTitles.loyalty_program
            : this.app.locale.listTitles.amway_total,
        value: formatCurrency(total),
      });

      return out;
    },
    businessPlusList() {
      if (this.Tree.businessNature === "VIP") return;

      let out = [];
      let total = 0;

      for (let key in this.Tree.business_plus) {
        out.push({
          label: this.app.locale.listTitles[key],
          value: Math.floor(this.Tree.business_plus[key])
            ? formatCurrency(this.Tree.business_plus[key])
            : "-",
        });
        total += this.Tree.business_plus[key];
      }

      out.push({
        label: this.app.locale.listTitles.business_plus_total,
        value: formatCurrency(total),
      });

      return out;
    },
  },

  methods: {
    formatCurrency,
    expandHandler(value, key) {
      this[`${key}_expanded`] = value;

      if (this.app.tutorialStep == "accordion-expand-helper") {
        if (this.amway_plan_expanded && this.business_plus_expanded) {
          this.changeTutorialStep("accordion-collapse-helper");
        }
      } else if (this.app.tutorialStep == "accordion-collapse-helper") {
        if (!this.amway_plan_expanded && !this.business_plus_expanded) {
          setTimeout(() => {
            if (this.app.tutorialStep)
              this.changeTutorialStep("canvas-move-helper");

            setTimeout(() => {
              if (this.app.tutorialStep)
                this.changeTutorialStep("canvas-zoom-helper");
            }, 2000);

            setTimeout(() => {
              if (this.app.tutorialStep)
                this.changeTutorialStep("settings-open");
            }, 4000);

            this.app.currentPopup.openSwitch();
          }, 1000);
        }
      }
    },
    update(key, value) {
      if (typeof value != "undefined" && typeof value != "object") {
        this.$emit("update", {
          id: this.vertex.id,
          key,
          value,
        });

        if (
          key == "personal_value" &&
          this.app.tutorialStep == "vertex-edit-1"
        ) {
          this.changeTutorialStep("vertex-edit-2");
        }
        if (key == "client_value" && this.app.tutorialStep == "vertex-edit-2") {
          setTimeout(() => {
            this.changeTutorialStep("vertex-personal-turnover-up");
            if (this.app.currentPopup) {
              this.app.currentPopup.openSwitch();
            }
          }, 10);
        }
      }
    },
    startEdit() {
      this.editingName = !this.editingName;
    },
    clearEditing({ target }) {
      const input = target.closest(".vertex-name input");
      const btn = target.closest(".shape");
      const btn2 = target.closest(".name");

      if (!input && !btn && !btn2) this.editingName = false;
    },
  },
  mounted() {
    if (this.app.calculator.clientType === "ABO") {
      this.update("flag_bronze_start", true);
    }

    this.$refs.popup.addEventListener("click", this.clearEditing);
  },
  // unmounted() {
  //   this.$refs.popup.removeEventListener('click', (this.clearEditing))
  // }
};
</script>
