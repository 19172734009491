<template>
  <section class="sections-products-select">
    <div class="swiper-container sections-products-select__slider">
      <div class="swiper-wrapper">
        <button
          v-for="(product, index) in products"
          :key="product.article"
          class="swiper-slide sections-products-select__slide"
          @click="onSlideClick(product.article, index)"
        >
          <img
            v-if="isSelected(product.article)"
            class="sections-products-select__check"
            src="/images/icons/check.svg"
            alt="check"
          />

          <img
            class="sections-products-select__image"
            :src="product.image"
            alt="image"
          />
        </button>
      </div>
    </div>

    <div class="sections-products-select__title">
      <span
        class="sections-products-select__font sections-products-select__font--title"
      >
        {{ activeProductName }}
      </span>
    </div>

    <div class="sections-products-select__info">
      <div class="sections-products-select__price">
        <span
          class="sections-products-select__font sections-products-select__font--price"
        >
          {{ formatCurrency(activeProduct.price) }}
        </span>
      </div>

      <div class="sections-products-select__points">
        <span
          class="sections-products-select__font sections-products-select__font--points"
        >
          {{ state.app.locale.redirectPage.points }}
          <span
            class="sections-products-select__font sections-products-select__font--points-accent"
          >
            {{ activeProduct.score }}
          </span>
        </span>
      </div>
    </div>

    <div class="sections-products-select__selection">
      <button
        class="sections-products-select__button"
        :class="`sections-products-select__button--${button.theme}`"
        @click="setSelected"
      >
        <span
          class="sections-products-select__font sections-products-select__font--button"
        >
          {{ button.text }}
        </span>
      </button>

      <div class="sections-products-select__counter">
        <span
          class="sections-products-select__font sections-products-select__font--counter"
        >
          <span
            class="sections-products-select__font sections-products-select__font--counter-accent"
          >
            {{ productsCount }} </span
          >/{{ products.length }}
        </span>
      </div>
    </div>

    <div class="sections-products-select__totals">
      <BlocksColoredInfo
        v-for="block in totalBlocks"
        :key="block.title"
        class="sections-products-select__total"
        :class="`sections-products-select__total--${block.modifier}`"
        :theme="block.theme"
      >
        <div
          class="sections-products-select__name"
          :class="`sections-products-select__name--${block.modifier}`"
        >
          <span
            class="sections-products-select__font sections-products-select__font--name"
          >
            {{ block.title }}
          </span>
        </div>

        <div class="sections-products-select__value">
          <span
            class="sections-products-select__font sections-products-select__font--value"
          >
            {{ block.value }}
          </span>
        </div>
      </BlocksColoredInfo>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper.scss";
import products from "@/config/products";
import BlocksColoredInfo from "@/components/blocks/BlocksColoredInfo.vue";
import { useState } from "@/App.store";
import formatCurrency from "../../../utils/helpers/formatCurrency";

export default {
  name: "SectionsProductsSelect",
  components: { BlocksColoredInfo },
  emits: ["select"],
  props: {
    productsCount: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    totalPoints: {
      type: Number,
      default: 0,
    },
    initialProducts: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      products,
    };
  },
  data() {
    const state = useState();

    return {
      slider: null,
      state,
      activeProduct: {},
      selectedProducts: [],
    };
  },
  computed: {
    isActiveSelected() {
      return this.isSelected(this.activeProduct.article);
    },
    activeProductName() {
      if (!this.activeProduct.name) return;

      return this.activeProduct.name[this.state.app.locale._code];
    },

    button() {
      if (this.isActiveSelected) {
        return {
          text: this.state.app.locale.redirectPage.remove,
          theme: "remove",
        };
      }

      return {
        text: this.state.app.locale.redirectPage.add,
        theme: "add",
      };
    },

    totalBlocks() {
      return [
        {
          title: this.state.app.locale.redirectPage.totalPrice,
          value: formatCurrency(this.totalPrice),
          theme: "blue",
          modifier: "month",
        },
        {
          title: this.state.app.locale.redirectPage.pricePerDay,
          value: formatCurrency(this.totalPrice / 30),
          theme: "green",
          modifier: "day",
        },
        {
          title: this.state.app.locale.redirectPage.personalPoints,
          value: this.totalPoints,
          theme: "yellow",
          modifier: "points",
        },
      ];
    },
  },
  mounted() {
    this.selectedProducts = [...this.initialProducts];
    this.activeProduct = this.products[0];

    this.initializeSwiper();
  },
  methods: {
    formatCurrency,

    initializeSwiper() {
      this.slider = new Swiper(".swiper-container", {
        slidesPerView: "auto",
        centeredSlides: true,
        observer: true,
        slideActiveClass: "sections-products-select__slide--active",
        on: {
          slideChange: (swiper) =>
            (this.activeProduct = this.products[swiper.activeIndex]),
        },
      });
    },

    isSelected(article) {
      return this.selectedProducts.find(
        (product) => product.article === article
      );
    },
    setSelected() {
      const index = this.selectedProducts.findIndex(
        (product) => product.article === this.activeProduct.article
      );

      if (index === -1) {
        this.selectedProducts.push(this.activeProduct);
      } else {
        this.selectedProducts.splice(index, 1);
      }

      this.$emit("select", this.selectedProducts);
    },

    onSlideClick(article, index) {
      if (article !== this.activeProduct.article)
        return this.slider.slideTo(index);

      this.setSelected(article);
    },
  },
};
</script>

<style scoped lang="scss">
.sections-products-select {
  $parent: &;

  @media screen and (max-width: 480px) {
    overflow: hidden;
  }

  &__slider {
    margin-bottom: 16px;
  }

  &__slide {
    position: relative;
    width: 256px;
    opacity: 0.4;
    padding: 0 16px;
    background-color: transparent;
    cursor: pointer;
    transform: scale(0.87);

    @media screen and (max-width: 480px) {
      width: 148px;
    }

    &--active {
      transform: none;
      opacity: 1;
    }
  }

  &__image {
    width: 100%;
    object-fit: contain;
  }

  &__check {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 6px;
    right: 18px;

    @media screen and (max-width: 480px) {
      right: 0;
      top: 5px;
      width: 40px;
      height: 40px;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 8px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__price {
    position: relative;
    color: $color-blue;
    margin-right: 24px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -12px;
      width: 1px;
      height: 24px;
      background-color: $color-mid-gray;
    }
  }

  &__selection {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
  }

  &__counter {
    position: absolute;
    top: 10px;
    left: calc(50% + 96px);
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 163px;
    height: 42px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      height: 36px;
    }

    &--add {
      color: $color-white;
      background-color: $color-blue;
    }

    &--remove {
      color: $color-dark-red;
      background-color: transparent;
      border: 2px solid $color-dark-red;
    }
  }

  &__totals {
    margin-bottom: 56px;

    @media screen and (max-width: 480px) {
      margin-bottom: 64px;
    }
  }

  &__total {
    padding: 16px 24px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &--points {
      padding: 8px;

      #{$parent} {
        &__font {
          &--name,
          &--value {
            font-size: 16px;
            line-height: 24px;

            @media screen and (max-width: 480px) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  &__name {
    margin-bottom: 8px;

    &--points {
      margin: 0;
    }
  }

  &__font {
    &--title {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 18px;
      line-height: 20px;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }

    &--price {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;

      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &--points {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 24px;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &--points-accent {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
    }

    &--counter {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 18px;
      line-height: 21px;
      color: $color-mid-gray;

      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &--counter-accent {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      color: $color-dark;

      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &--button {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &--name {
      display: block;
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 18px;
      line-height: 21px;

      @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &--value {
      display: block;
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 34px;
      font-weight: 700;
      line-height: 36px;

      @media screen and (max-width: 480px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
}
</style>
