/* eslint-disable */

<template>
  <div class="settings-popup">
    <div class="group">
      <Button
        icon="plus"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#444444"
        style="color: #444444"
        @click="Reset"
      >
        {{ app.locale.buttons.new_calc }}
      </Button>
      <Button
        class="settings-popup__save-button"
        icon="save"
        icon-position="left"
        :icon-size="buttonIconSize"
        :icon-color="saveSuccess ? '#3FA43D' : '#444444'"
        :style="`color: ${saveSuccess ? '#3FA43D' : '#444444'}`"
        @click="save"
      >
        {{
          saveSuccess
            ? app.locale.buttons.save_calc_success
            : app.locale.buttons.save_calc
        }}
      </Button>
      <Popup class="settings-btn">
        <template v-slot:popup-button>
          <Button
            icon="upload"
            :icon-position="'left'"
            :icon-size="buttonIconSize"
            icon-color="#444444"
            :style="{ color: '#444444' }"
          >
            {{ app.locale.buttons.upload_calc }}
          </Button>
        </template>
        <template v-slot:default>
          <UploadPopup />
        </template>
      </Popup>
    </div>
    <hr class="divider" v-if="app.calculator.clientType === 'ABO'" />
    <div class="group" v-if="app.calculator.clientType === 'ABO'">
      <Button
        style="color: #444444"
        @click="load(`temp100000`)"
      >
        {{ app.locale.example_calc_for }}
        <b style="margin-left: 4px"
          >100&nbsp;000 {{ app.locale.currency_symbol }}&nbsp;</b
        >
      </Button>

      <Button
        style="color: #444444"
        @click="load(`temp500000`)"
      >
        {{ app.locale.example_calc_for }}
        <b style="margin-left: 4px"
          >300&nbsp;000 {{ app.locale.currency_symbol }}&nbsp;</b
        >
      </Button>

      <Button
        style="color: #444444"
        @click="load(`temp1000000`)"
      >
        {{ app.locale.example_calc_for }}
        <b style="margin-left: 4px"
          >600&nbsp;000 {{ app.locale.currency_symbol }}&nbsp;</b
        >
      </Button>
    </div>
    <hr class="divider" />
    <div class="group">
      <!-- <Button
        icon="instruction"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#444444"
        style="color: #444444"
      >
        {{ app.locale.buttons.instructions }}
      </Button> -->
        <a
                class="btn withIcon"
                style="color: #444444"
                :href="app.locale.redirectPage.how_works_link"
                target="_blank"
        >
            <svg width="21" style="margin-right: 8px;margin-left: -2px;" height="20" viewBox="0 0 21 20" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#2C2C2C"/>
                <path d="M13.9643 9.35405L8.46454 6.10262C8.01768 5.83857 7.33333 6.09481 7.33333 6.74791V13.2492C7.33333 13.8351 7.96924 14.1882 8.46454 13.8945L13.9643 10.6446C14.4549 10.3556 14.4565 9.6431 13.9643 9.35405Z" fill="#2C2C2C"/>
            </svg>
            {{ app.locale.redirectPage.how_works }}

        </a>
      <a
        class="btn withIcon"
        style="color: #444444"
        :href="app.locale.instruction"
        download
      >
        <Icon
          class="icon left"
          :class="iconPosition"
          name="instruction"
          color="#444444"
          :width="buttonIconSize[0]"
          :height="buttonIconSize[1]"
        />
        {{ app.locale.buttons.instructions }}

      </a>

      <!--      <Button-->
      <!--        icon="learn"-->
      <!--        icon-position="left"-->
      <!--        :icon-size="buttonIconSize"-->
      <!--        icon-color="#444444"-->
      <!--        style="color: #444444"-->
      <!--        @click="app.saved ? startTutorial : (startTutorialWithUnsave = true)"-->
      <!--      >-->
      <!--        {{ app.locale.buttons.start_learn }}-->
      <!--      </Button>-->
      <Button
        icon="close"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#D64848"
        style="color: #d64848"
      >
        {{ app.locale.buttons.exit }}
      </Button>
    </div>

    <Popover
      v-if="app.tutorialStep == 'settings-popup-helper'"
      :class="this.app.tutorialStep"
      :title="this.app.locale.tutorial['settings-popup-title']"
      :text="this.app.locale.tutorial['settings-popup-text']"
      :isArrow="true"
      arrowPosition="top"
      arrowAlign="left"
    />
  </div>

  <div
    v-if="
      startTutorialWithUnsave ? true : !app.saved && startTutorialWithUnsave
    "
    class="unsave-tutorial-message"
  >
    <div class="message">
      <div class="title">
        {{ app.locale.unsave.title }}
      </div>
      <div class="text">
        {{ app.locale.unsave.text }}
      </div>
    </div>

    <div class="actions">
      <Button
        class="settings-popup__save-button"
        id="save"
        type="basic"
        icon="save"
        icon-position="right"
        :icon-size="buttonIconSize"
        :icon-color="'#FFFFFF'"
        :style="{ color: '#FFFFFF' }"
        @click="save"
      >
        {{
          saveSuccess
            ? app.locale.buttons.save_calc_success
            : app.locale.buttons.save_calc
        }}
      </Button>
      <!--      <Button-->
      <!--        id="startTutor"-->
      <!--        type="basic"-->
      <!--        icon="learn"-->
      <!--        icon-position="right"-->
      <!--        :icon-size="buttonIconSize"-->
      <!--        icon-color="#034580"-->
      <!--        style="color: #034580"-->
      <!--        @click="startTutorial"-->
      <!--      >-->
      <!--        {{ app.locale.buttons.start_learn }}-->

      <!--      </Button>-->
    </div>
  </div>
</template>
<script>
import { Button, Popup, Icon, Popover } from "@components/UI";
import { useState } from "@src/App.store.js";
import { baseUrl } from "@src/API/Fetch.js";
import "./Settings.scss";

import UploadPopup from "@src/popups/Upload";

export default {
  name: "SettingsPopup",
  components: {
    Button,
    Popup,
    Icon,
    UploadPopup,
    Popover,
  },
  setup() {
    return useState();
  },
  data() {
    return {
      buttonIconSize: ["16px", "16px"],
      saveSuccess: false,
      startTutorialWithUnsave: false,
    };
  },
  computed: {
    downloadLink() {
      return new URL(`/node/${this.Tree.id}/pdf`, baseUrl);
    },
  },
  methods: {
    save() {
      this.saveSuccess = true;
      this.saveTree();
      setTimeout(() => {
        this.saveSuccess = false;
      }, 10000);
    },
    load(id) {
      // this.app.currn
      this.loadTree(id);
      this.app.currentPopup.openSwitch();
    },
  },
};
</script>
