export default [
  {
    priority: 10,
    article: 317528,
    link: "https://www.kz.amway.com/kk/p/317528",
    name: {
      kz: "Body Guru by Nutrilite™ Белсенді салмақ жоғалту бағдарламасын",
      mn: "Bundle Active Weight loss mix Vanilla Chocolate Body Guru by Nutrilite™",
      ky: "Bundle Active Weight loss mix Vanilla Chocolate Body Guru by Nutrilite™",
      ru: "Набор Активное снижение веса программы mix ваниль шоколад Body Guru by Nutrilite™",
    },
    price: 179607,
    score: 204.61,
    image: "/images/products/317528(800х800).png",
  },
  {
    priority: 20,
    article: 313101,
    link: "https://www.kz.amway.com/kk/p/313101",
    name: {
      kz: "NUTRILITE™ Body Detox жиынтығы",
      mn: "NUTRILITE™ Body Detox bundle",
      ky: "NUTRILITE™ Body Detox bundle",
      ru: "NUTRILITE™ Body Detox набор",
    },
    price: 73146,
    score: 83.33,
    image: "/images/products/313101(800х800).png",
  },
  {
    priority: 30,
    article: 307717,
    link: "https://www.kz.amway.com/kk/p/307717",
    name: {
      kz: "Фитнеспен айналысатындарға арналған BODY LOGIC жиынтығы",
      mn: "BODY LOGIC fitness bundle",
      ky: "BODY LOGIC fitness bundle",
      ru: "BODY LOGIC набор для тех, кто занимается фитнесом",
    },
    price: 24386,
    score: 27.78,
    image: "/images/products/307717(800х800).png",
  },
  {
    priority: 40,
    article: 307777,
    link: "https://www.kz.amway.com/kk/p/307777",
    name: {
      kz: "Nutrilite™ Сау Ішек жиынтығы",
      mn: "Nutrilite™ Healthy gut bundle",
      ky: "Nutrilite™ Healthy gut bundle",
      ru: "Nutrilite™ набор Здоровый кишечник",
    },
    price: 39425,
    score: 44.91,
    image: "/images/products/307777(800х800).png",
  },
  {
    priority: 50,
    article: 303246,
    link: "https://www.kz.amway.com/kk/p/303246",
    name: {
      kz: "NUTRILITE™ «Функционалдық тамақтану» күшейтілген жинағы Double X-пен -10% жеңілдікпен",
      mn: "NUTRILITE™ Functional food bundle Double X discount -10%",
      ky: "NUTRILITE™ Functional food bundle Double X discount -10%",
      ru: "NUTRILITE™ Набор Функциональное питание с Double X скидка -10%",
    },
    price: 44022,
    score: 50.15,
    image: "/images/products/303246(800х800).png",
  },
  {
    priority: 60,
    article: 121576,
    link: "https://www.kz.amway.com/kk/p/121576",
    name: {
      kz: "Құрамында дәрумендер, минералдар мен фитонутриенттер бар NUTRILITE™ Double X, 186 таб.",
      mn: "NUTRILITE™ DOUBLE X™ with vitamins, minerals and phytonutrients, 186 tab. NUTRILITE™",
      ky: "NUTRILITE™ DOUBLE X™ with vitamins, minerals and phytonutrients, 186 tab. NUTRILITE™",
      ru: "NUTRILITE™ DOUBLE X™ с витаминами, минералами и фитонутриентами, 186 таб. NUTRILITE™",
    },
    price: 20621,
    score: 23.49,
    image: "/images/products/121576(800х800).png",
  },
  {
    priority: 80,
    article: 4298,
    link: "https://www.kz.amway.com/kk/p/4298",
    name: {
      kz: "NUTRILITE™ Омега-3 кешені, 90 капс.",
      mn: "NUTRILITE™ Omega-3 Complex, 90 caps.",
      ky: "NUTRILITE™ Omega-3 Complex, 90 caps.",
      ru: "NUTRILITE™ Омега-3 комплекс, 90 капс.",
    },
    price: 13120,
    score: 14.95,
    image: "/images/products/4298(800х800).png",
  },
  {
    priority: 90,
    article: 122447,
    link: "https://www.kz.amway.com/kk/p/122447",
    name: {
      kz: "NUTRILITE™ Балаларға арналған Омега-3 D дәрумені бар шайналатын пастилкалар*, 30 дана",
      mn: "NUTRILITE™ Concentrated Fruits and Vegetables, 60 tab.",
      ky: "NUTRILITE™ Concentrated Fruits and Vegetables, 60 tab.",
      ru: "NUTRILITE™ Омега-3 с витамином D детские желейные пастилки, 30 шт.",
    },
    price: 9091,
    score: 10.36,
    image: "/images/products/122447(800х800).png",
  },
  {
    priority: 70,
    article: 312790,
    link: "https://www.kz.amway.com/kk/p/312790",
    name: {
      kz: "NUTRILITE™ Meal Replacement Shake Chocolate шоколад дәмімен протеинді коктейльді әзiрлеуге арналған байытылған қоспа",
      mn: "NUTRILITE™ Meal Replacement Shake Chocolate",
      ky: "NUTRILITE™ Meal Replacement Shake Chocolate",
      ru: "NUTRILITE™ Обогащенная смесь для приготовления протеинового коктейля Meal Replacement Shake Chocolate cо вкусом шоколада",
    },
    price: 22950,
    score: 26.14,
    image: "/images/products/312790(800x800).png",
  },
    {
        priority: 100,
        article: 319068,
        link: "https://www.kz.amway.com/kk/p/319068",
        name: {
            kz: "Nutrilite™ «Өзіңді ләззатпен күту» жиынтығы ",
            mn: "Nutrilite™ Self-care for pleasure ",
            ky: "Nutrilite™ Self-care for pleasure ",
            ru: "Nutrilite™ Набор Забота о себе в удовольствие",
        },
        price: 23688,
        score: 26.98,
        image: "/images/products/319068(800x800).png",
    },
    {
        priority: 110,
        article: 308612,
        link: "https://www.kz.amway.com/kk/p/308612",
        name: {
            kz: "Nutrilite™ Сергектік миксі Can’t stop Won’t stop™",
            mn: "Nutrilite™ Can’t stop Won’t stop™",
            ky: "Nutrilite™ Can’t stop Won’t stop™",
            ru: "Nutrilite™ «Микс Бодрости» Can’t stop Won’t stop™",
        },
        price: 8899,
        score: 10.14,
        image: "/images/products/308612(800x800).png",
    },
  {
    priority: 120,
    article: 124919,
    link: "https://www.kz.amway.com/kk/p/124919",
    name: {
      kz: "ARTISTRY SIGNATURE SELECT™ «Барлығы және бірден» жинағы",
      mn: "ARTISTRY SIGNATURE SELECT™ «Body fitness»",
      ky: "ARTISTRY SIGNATURE SELECT™ «Body fitness»",
      ru: "ARTISTRY SIGNATURE SELECT™ Набор «Боди фитнес»",
    },
    price: 42259,
    score: 48.14,
    image: "/images/products/124919(800х800).png",
  },
  {
    priority: 110,
    article: 315362,
    link: "https://www.kz.amway.com/kk/p/315362",
    name: {
      kz: "ARTISTRY SKIN NUTRITION™ С дәрумені және гиалурон қышқылы сарысуы + Super Peptide Nutrilite™",
      mn: "Bundle Vitamin C+HA3 Daily Serum ARTISTRY Skin Nutrition™ +Super Peptide Nutrilite™",
      ky: "Bundle Vitamin C+HA3 Daily Serum ARTISTRY Skin Nutrition™ +Super Peptide Nutrilite™",
      ru: "Набор ARTISTRY SKIN NUTRITION™ Cыворотка с витамином С и гиалуроновой кислотой + Super Peptide Nutrilite™",
    },
    price: 55051,
    score: 62.71,
    image: "/images/products/315362(800х800).png",
  },
  {
    priority: 120,
    article: 119293,
    link: "https://www.kz.amway.com/kk/p/119293",
    name: {
      kz: "Super Peptide Nutrilite™",
      mn: "Super Peptide Nutrilite™",
      ky: "Super Peptide Nutrilite™",
      ru: "Super Peptide Nutrilite™",
    },
    price: 17259,
    score: 19.66,
    image: "/images/products/119293(800х800).png",
  },
  {
    priority: 130,
    article: 303620,
    link: "https://www.kz.amway.com/kk/p/303620",
    name: {
      kz: "ARTISTRY SIGNATURE SELECT™ «Нұрланып тұрғандайсың» жинағы",
      mn: "ARTISTRY SIGNATURE SELECT™ Glowing skin face masks bundle",
      ky: "ARTISTRY SIGNATURE SELECT™ Glowing skin face masks bundle",
      ru: "ARTISTRY SIGNATURE SELECT™ Набор «Ты словно светишься»",
    },
    price: 41631,
    score: 47.43,
    image: "/images/products/303620(800х800).png",
  },
  {
    priority: 140,
    article: 303621,
    link: "https://www.kz.amway.com/kk/p/303621",
    name: {
      kz: "ARTISTRY SIGNATURE SELECT™  «Лездік лифтинг»",
      mn: "ARTISTRY SIGNATURE SELECT™  Instant lifting face masks bundle",
      ky: "ARTISTRY SIGNATURE SELECT™  Instant lifting face masks bundle",
      ru: "ARTISTRY SIGNATURE SELECT™ Набор «Мгновенный лифтинг»",
    },
    price: 41631,
    score: 47.43,
    image: "/images/products/303621(800х800).png",
  },
  {
    priority: 150,
    article: 124827,
    link: "https://www.kz.amway.com/kk/p/124827",
    name: {
      kz: "ARTISTRY SKIN NUTRITION «Жаңару және серпімділік артуы»",
      mn: "ARTISTRY SKIN NUTRITION™ Renewing and lifting bundle",
      ky: "ARTISTRY SKIN NUTRITION™ Renewing and lifting bundle",
      ru: "ARTISTRY SKIN NUTRITION™ Набор «Обновление и Повышение упругости»",
    },
    price: 79419,
    score: 90.47,
    image: "/images/products/124827(800х800).png",
  },
  {
    priority: 160,
    article: 124713,
    link: "https://www.kz.amway.com/kk/p/124713",
    name: {
      kz: "ARTISTRY SKIN NUTRITION™ Кешкі күтімге арналған «Ылғалдандыру» жинағы",
      mn: "ARTISTRY SKIN NUTRITION™ Hydration evening routine bundle",
      ky: "ARTISTRY SKIN NUTRITION™ Hydration evening routine bundle",
      ru: "ARTISTRY SKIN NUTRITION™ Набор «Увлажнение» Вечерний уход",
    },
    price: 34498,
    score: 39.3,
    image: "/images/products/124713(800х800).png",
  },
  {
    priority: 170,
    article: 124106,
    link: "https://www.kz.amway.com/kk/p/124106",
    name: {
      kz: "Glister™ Kөп функционалды тіс пастасы",
      mn: "Glister™ Multi-Action Toothpaste",
      ky: "Glister™ Multi-Action Toothpaste",
      ru: "Glister™ Многофункциональная зубная паста",
    },
    price: 2949,
    score: 3.36,
    image: "/images/products/124106(800х800).png",
  },
  {
    priority: 180,
    article: 124108,
    link: "https://www.kz.amway.com/kk/p/124108",
    name: {
      kz: "Glister™ Aуыз қуысына арналған көп функционалды ауыз шайғыш",
      mn: "Glister™ Multi-Action Oral Rinse",
      ky: "Glister™ Multi-Action Oral Rinse",
      ru: "Glister™ Многофункциональный ополаскиватель для полости рта",
    },
    price: 5270,
    score: 6,
    image: "/images/products/124108(800х800).png",
  },
  {
    priority: 190,
    article: 124112,
    link: "https://www.kz.amway.com/kk/p/124112",
    name: {
      kz: "Glister™ Kөп функционалды тіс тазалайтын жіп",
      mn: "Glister™ Dental Floss",
      ky: "Glister™ Dental Floss",
      ru: "Glister™ Многофункциональная зубная нить",
    },
    price: 4161,
    score: 4.74,
    image: "/images/products/124112(800х800).png",
  },
  {
    priority: 200,
    article: 118107,
    link: "https://www.kz.amway.com/kk/p/118107",
    name: {
      kz: "G&H NOURISH+™ Душқа арналған гель",
      mn: "G&H NOURISH+™ Shower gel",
      ky: "G&H NOURISH+™ Shower gel",
      ru: "G&H NOURISH+™ Гель для душа",
    },
    price: 5057,
    score: 5.76,
    image: "/images/products/118107(800х800).png",
  },
  {
    priority: 210,
    article: 118117,
    link: "https://www.kz.amway.com/g-h-protect-koncentrirovannoe-zhidkoe-mylo-g-h/p/118117",
    name: {
      kz: "G&H PROTECT+™ Қолға арналған қойылтылған сұйық сабын",
      mn: "G&H PROTECT+™ Concentrated hand soap gel",
      ky: "G&H PROTECT+™ Concentrated hand soap gel",
      ru: "G&H PROTECT+™ Концентрированное жидкое мыло",
    },
    price: 3493,
    score: 3.98,
    image: "/images/products/118117(800х800).png",
  },
  {
    priority: 220,
    article: 115304,
    link: "https://www.kz.amway.com/kk/satinique-shampun-kondicioner-2-v-1-satinique/p/115304",
    name: {
      kz: "SATINIQUE™ «1-е 2-у» кондиционер-сусабыны",
      mn: "SATINIQUE™ 2 in 1 Shampoo & Conditioner",
      ky: "SATINIQUE™ 2 in 1 Shampoo & Conditioner",
      ru: "Шампунь-кондиционер 2 в 1 SATINIQUE™",
    },
    price: 5465,
    score: 6.23,
    image: "/images/products/115304(800х800).png",
  },
  {
    priority: 230,
    article: 118120,
    link: "https://www.kz.amway.com/kk/p/118120",
    name: {
      kz: "G&H PROTECT+ Шариктік дезодорант-антиперспирант",
      mn: "G&H PROTECT+™ Deodorant & anti-perspirant roll-on",
      ky: "G&H PROTECT+™ Deodorant & anti-perspirant roll-on",
      ru: "G&H PROTECT+™ Шариковый дезодорант-антиперсперант",
    },
    price: 3532,
    score: 4.02,
    image: "/images/products/118120(800х800).png",
  },
];
