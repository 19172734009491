<template>
  <div class="checkbox-container" @click="currentChecked = !currentChecked">
    <div class="box">
      <Icon
        v-if="currentChecked"
        name="check"
        color="#000000"
        :height="app.breakpoint.mobile ? '8px' : '13px'"
        :width="app.breakpoint.mobile ? '10px' : '12px'"
      />
    </div>
    <div class="label" v-if="label">
      {{ label }}
    </div>
  </div>
</template>
<script>
import { Icon } from "@components/UI";
import { useState } from "@src/App.store.js";
import "./Checkbox.scss";

export default {
  name: "Checkbox",
  components: {
    Icon,
  },
  props: {
    label: {
      default: null,
    },
    checked: {
      default: false,
    },
  },
  data() {
    return {
      currentChecked: this.checked,
    };
  },
  setup() {
    return useState();
  },
  watch: {
    checked() {
      this.currentChecked = this.checked;
    },
    currentChecked() {
      this.$emit("change", this.currentChecked);
    },
  },
};
</script>
