<template>
  <LayoutDefault>
    <Layout class="pages-main" :showFooter="page == 'start'">
      <transition name="fade-start">
        <Start v-if="page == 'start'" :class="{ fade: isCalc }" />
      </transition>

      <transition name="fade-calc">
        <Calculator v-if="page == 'calc'" />
      </transition>
      <PopupContainer />

      <!--      <Tutorial />-->
    </Layout>
  </LayoutDefault>
</template>

<script>
import Layout from "@/components/UI/Layout/Layout";
import Start from "@/pages/start";
import Calculator from "@/pages/calculator";
import PopupContainer from "@/components/UI/Popup/Popup-container";
import { useState } from "@src/App.store.js";
import LayoutDefault from "@/layout/LayoutDefault.vue";

export default {
  name: "Main",
  components: {
    LayoutDefault,
    PopupContainer,
    Calculator,
    Start,
    Layout,
  },
  data() {
    return {
      page: "start",
    };
  },
  setup() {
    return useState();
  },
  computed: {
    isCalc() {
      return (
        Boolean(this.app.calculator.clientType) &&
        Boolean(this.app.calculator.country)
      );
    },
  },
  watch: {
    isCalc() {
      if (this.isCalc)
        setTimeout(() => {
          this.page = "calc";
        }, 500);
      // setTimeout(()=>{
      else this.page = "start";
      // }, 500)
    },
  },
};
</script>

<style scoped lang="scss">
.pages-main {
  padding-top: 52px;
}
</style>
