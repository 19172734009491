export default {
    _code: "kz",
    PPV: "Жеке",
    GPV: "Топтық",
    startTitle: "Amway Калькуляторы",
    invitationSelectCountry: "",
    invitationSelectClientType:
        "Amway компаниясымен ынтымақтастық түрін таңдаңыз. Amway Тәуелсіз Кәсіпкері немесе VIP+ Клиенті",
    income_label: "Кіріс",
    rules_text: "Қолдану ережелері",
    example_calc_for: "үшін есептеу мысалы",

    group_points_title_vertexEdit: "Топтық <br /> ұпайлар",
    total: "Жиыны",
    personal_friends_buy: "+ жеке сатып алулар мен достар сатып алу үшін",

    upload_title: "Сіздің сақталған есептеулеріңіз",
    upload_calc: "Есептеу",
    upload_calc_cost: "сомасына",

    buttons: {
        settings: "Баптаулар",
        new_calc: "Жаңа есептеу",

        save_calc: "Есептеуді сақтау",
        save_calc_success: "Есептеу сәтті сақталды!",

        upload_calc: "Есептеуді жүктеу",
        download_calc: "Есепті жүктеп алыңыз",
        instructions: "Нұсқаулық",
        start_learn: "Оқудан өту",
        exit: "Шығу",

        edit: "Ақпаратты <br /> өңдеу",

        skip_tutorial: "Оқулықты өткізіп жіберу",
        continue: "Жалғастыру",
    },

    inputs: {
        personal_points: "Жеке ұпайлар",
        client_value: "Клиенттердің ұпайлары",
    },

    checkboxes: {
        guardianship: "Сенімгердің сыйақысы",
        guardianship_branch: "Қамқоршылық Тармақ",
        bronze_start: "Қола Cтарт⁺",
        bronze_start_mobile: `Қола Cтарт⁺`,
        bronze_bonus: `Қола Төлем⁺`,
        bronze_bonus_mobile: `Қола Төлем⁺`,
    },

    listTitles: {
        personal_achievements: "Жеке жетістіктері үшін",
        personal_percent_difference: "% айырмашылығы үшін",
        retail_margin: "Досыңа айт",
        leader_reward: "Көшбасшылық үшін",
        sponsor_reward: "Қамқоршылық",
        ruby_reward: "Лағыл",
        pearl_reward: "Інжу",
        amway_total: "Amway жоспары бойынша жиыны",
        personal_sells_amount: "Жеке сату көлемі үшін жиыны",
        personal_friends_buy: "Жеке сатып алу және достар сатып алу үшін жиыны",
        loyalty_program: "Жалпы ниеттестік бағдарламасы",

        friends_discount: `Клиенттердің сатып алуларына жеңілдіктер⁺`,
        bronze_start: `Қола старт⁺`,
        bronze_reward: `Қола төлемі⁺`,
        elite_plus: `Элит⁺`,
        business_plus_total: `Бизнес Плюс⁺`,
    },

    unsave: {
        title: "Сақталмаған есептеу.",
        text: "Жаттығуға барған кезде ағымдағы есебіңіз өшіріледі. Аяқталмаған есептеулеріңізді оқулықтан бұрын сақтауға болады.",
    },

    tutorial: {
        clientType_title: "Amway калькуляторына қош келдіңіз!",
        clientType_text: "Клиенттің кез келген түрін таңдаңыз",

        country_text: " Сайтты көрсету тілі",

        "vertex-start-edit": `Әрбір "шеңбер" Жеке ұпайларды, Топтық ұпайларды және жеке төлемдер мөлшерін қамтиды <br /> <br /> Өңдеу мәзіріне кіру үшін ортадағы "шеңбер" немесе көп нүктені басыңыз`,

        "vertex-edit-title": "Өңдеу мәзірі",
        "vertex-edit-1-text":
            " Жеке ұпайларыңызды 10 көбейту немесе қолмен енгізу үшін + түймесін басыңыз.",
        "vertex-edit-2-text":
            " Тұтынушы ұпайларының санын 10 көбейту немесе қолмен енгізу үшін + түймесін басыңыз.",

        "vertex-personal-turnover-up-title": "Жеке айналым өсті",
        "vertex-personal-turnover-up-text":
            " жеке ұпайлар топтық ұпайлар мен бонус сомасында да көрсетіледі.",

        "vertex-ext-menu-title": "Қосымша мәзір",
        "vertex-ext-menu-text":
            "Қосымша мәзірді ашу үшін шеңбердің жоғарғы оң жақ бұрышындағы белгішені басыңыз",

        "vertex-add-partner-title": "Серіктес қосу",
        "vertex-add-partner-text":
            "Тобыңызға жаңа серіктес қосу үшін «плюс» түймесін басыңыз.",

        "vertex-add-partner-2-title": "Серіктес қосу",
        "vertex-add-partner-2-text": "Топтық ұпайлардың өсуіне назар аударыңыз.",

        "vertex-add-partner-3-title": "Біздің мақсатымыз 3 серіктес",
        "vertex-add-partner-3-text":
            " Бірінші қатардағы үш негізгі серіктес - жақсы бастама.",

        "vertex-hide-tree-title": '"Бөлімшені" жию немесе кеңейту',
        "vertex-hide-tree-text":
            "Осы топтағы барлық серіктестерді жасыру немесе көрсету үшін түймені басыңыз.",

        "vertex-view-detail-info-title": "Егжей-тегжейлі ақпаратты қарау",
        "vertex-view-detail-info-text":
            'Өңдеу мәзіріне кіру үшін ортадағы "шеңбер" немесе көп нүктені басыңыз',

        "accordion-expand-helper-title": "Егжей",
        "accordion-expand-helper-text":
            "«Amway жоспары бойынша жиынтық» және «Бизнеске арналған жиынтық +» блоктарын кеңейтіңіз. Мұны істеу үшін жай ғана осы блоктарды басыңыз.",

        "accordion-collapse-helper-title": "Блоктарды ораңыз",
        "accordion-collapse-helper-text": "«Жыйыру» түймесіндегі блоктарды жию.",

        "canvas-move-helper-title": "қозғалады",
        "canvas-move-helper-text": "Экранда еркін қозғала алады",

        "canvas-zoom-helper-title": "Масштаб",
        "canvas-zoom-helper-text": " Сондай-ақ экранды үлкейтіп, еркін жылжытыңыз",

        "settings-open-title": "Калькулятор мәзірі",
        "settings-open-text":
            "Калькулятордың қосымша функциялары оң жақтағы мәзірде орналасқан.",

        "settings-popup-title": "Қосымша функциялар",
        "settings-popup-text":
            "Бұл терезеде есептеуді кейінірек қайтару үшін сақтауға, клиенттерге көрсету үшін бұрын жасалған есептеулерді жүктеуге, сондай-ақ кірістің әртүрлі сомалары үшін есептеудің алдын ала орнатуларын пайдалануға болады.",

        "end-title": "Құттықтаймыз!",
        "end-text": "Табысты бизнес құрыңыз.",
    },

    currency_symbol: "₸",
    accord_hide: "Жасыру",
    lang_npa: "АТК",
    lang_vip: "VIP+",
    instruction: "/Amway_Calculator_instruction_KZ_1.pdf",
    enter_name: "Атыңызды енгізіңіз",
    copyrate: `© 2011-${new Date().getFullYear()}Amway Europe. Барлық құқықтар сақталған.`,
    clientTypeOptions: [
        {
            code: "ABO",
            label: "АТК",
        },
        {
            code: "VIP",
            label: "VIP+ Клиенті",
        },
    ],
    duplicate: "Көшірме",
    redirectPage: {
        routes: {
            product: "Өнім",
            discount: "Жеңілдіктер",
            income: "Табыс",
        },
        how_works:'Калькулятор қалай жұмыс істейді?',
        how_works_link:'https://vimeo.com/871479214',
        description:
            "Серіктестіктердің әлеуетті төлемдері мен артықшылықтарының нәтижесі және Amway тұтынушылары шамамен, бірақ&nbsp;кепілдіксіз&nbsp;табыс. Есептелген табыс жеке сатып алу көлеміне, тұтынушының сатып алу көлеміне, құруға байланысты серіктестердің жеке тобы.",
        navigation: {
            next: "Әрі қарай",
            prev: "Артқа",
        },
        add: "Қосу",
        remove: "Жою",
        totalPrice: "Жалпы бағасы",
        pricePerDay: "Күніне баға*",
        personalPoints: "Жеке ұпайлар",
        groupPoints: "Топтық ұпайлар",
        total: "Барлығы",
        products: "Тауарлар",
        potentialIncome: "Серіктестің әлеуетті кірісі",
        profit: "Сатып алудан пайда алыңыз",
        friends: "Сіздің достарыңыз",
        friendsFriend: "Таныс достар",
        client: "Сатып алушылар",
        vipClient: "VIP сатып алушылар",
        errors: {
            selectOne: "Жалғастыру үшін кемінде бір өнімді таңдаңыз",
        },
        understandable: "Ол түсінікті",
        points: "Ұпайлар",
        loyalty: {
            tooltipPersonalActivity:
                "Адалдық бағдарламасы бойынша жеке жетістіктер үшін бір бонус",
            tooltipGroupActivity:
                "Үнэнч байдлын хөтөлбөрийн дагуу% -ийн зөрүүний хувьд",
        },
        amway: {
            tooltipPersonalActivity:
                "Amway жоспары бойынша жеке жетістіктер үшін бір бонус",
            tooltipGroupActivity: "Amway жоспары бойынша % айырмашылығы үшін",
        },
        forPersonalActivity: "Жеке белсенділік үшін",
        forGroupActivity: "Топтық әрекет үшін",
        monthDiscount: "Айына&nbsp;жалпы жеңілдік",
        forStableBusiness: "Тұрақты бизнес үшін",
        tooltipStableBusiness: "Business Plus+",
        forGuardianship: "Тәлімгерлік үшін",
        tooltipGuardianship: "Көшбасшылық, Ruby, Pearl төлемдері үшін",
        monthPayments: "Айына жалпы төлем",
        profitText:
            "<strong>Тұтынушы VIP+</strong> - өнімдерді&nbsp;өзі үшін сатып алады және достары мен&nbsp;таныстарына ұсынады, олардың компанияның клиенті болуға көмектеседі. Әртүрлі бағдарламалар үшін жеңілдік шотына қосымша жеңілдіктер алады.",
        incomeText:
            "<strong>Amway Серіктесі</strong> — «Amway Kazakhstan» ЖШС-мен Шарт жасасқан заңды тұлға (ЖК/ЖШС) мәртебесіне ие. Өнімдерді соңғы тұтынушыларға қайта сата алады және серіктестік желісін дамыта алады. Ол үшін төлемдерді ағымдағы шотына алады.",
        footerTextOne:
            "* Бір күндік баға ақпараттық мақсатта және бір ай пайдалану негізінде берілген. Бұл бөліп төлеу емес. Тауардың толық құны сатып алу кезінде төленеді.",
        footerTextTwo:
            "Алынған нәтижелер шамамен алынған, бірақ ЖОҚ кепілдендірілген табыс. <br /> Есептелген кіріс сатып алудың жеке көлеміне, сатып алу көлеміне байланысты серіктестер тобын құру арқылы тұтынушылар.",
        langSelectTitle: "Тіл таңдау",
        langSelect_ru: "Русский",
        langSelect_kz: "Қазақ",
        langSelect_mn: "Монгол",
        langSelect_ky: "Кыргызча",
currencySelectTitle: "Валюта таңдау",
        currencySelect_ru: "Рубли",
        currencySelect_kz: "Тенге",
        currencySelect_mn: "Тугрик",
        currencySelect_ky: "Сом",
    currencySelectDescription:
"Қазақстандық теңгеден (₸) басқа валютада көрсетілген бағалар шамамен алынған және ұлттық валюталардың АҚШ долларына қатысты кросс-бағамы бойынша есептеледі. Amway Серіктестері мен Клиенттерінің әлеуетті төлемдері мен пайдасы тек теңгемен есептеледі және төленеді.",
},
};
