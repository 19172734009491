<template>
  <div class="start-page">
    <h1 class="start-title">
      {{ app.locale.startTitle }}
    </h1>
    <InlineSelect
      :class="[
        {
          'current-tutorial': app.tutorialStep == `country`,
        },
      ]"
      :options="selectCountry"
      :invitation="app.locale.invitationSelectCountry"
      :value="app.calculator.country ? app.locale._code : null"
      v-on:change="setCountry"
      v-if="!this.selectedLocale"
      :tutorial="
        app.tutorialStep == `country` && {
          text: app.locale.tutorial.country_text,
        }
      "
    />
    <InlineSelect
      :class="[
        {
          'current-tutorial': app.tutorialStep == `clientType`,
        },
      ]"
      :options="app.locale.clientTypeOptions"
      :value="app.calculator.clientType"
      :invitation="app.locale.invitationSelectClientType"
      v-on:change="setClientType"
      v-if="this.selectedLocale"
      :tutorial="
        app.tutorialStep == `clientType` && {
          title: app.locale.tutorial.clientType_title,
          text: app.locale.tutorial.clientType_text,
        }
      "
    />

    <div class="start-links">
      <a
        class="start-link"
        href="/20201223-Calculator-disclaimer.ru-kz-mn.pdf"
        target="_blank"
      >
        {{ app.locale.rules_text }}
      </a>
    </div>
  </div>
</template>
<script>
import { InlineSelect } from "@components/UI";
import { useState } from "@src/App.store.js";

import "./start.scss";

export default {
  name: "startPage",
  selectedLocale: "",

  components: {
    InlineSelect,
  },
  setup() {
    return useState();
  },
  mounted() {
    this.setClientType(null);
    this.selectedLocale = localStorage.getItem("locale");
    this.setCountry(this.selectedLocale);
  },
  data() {
    return {
      selectedLocale: undefined,
      clientTypeOptions: [
        {
          code: "ABO",
          label: this.app.locale.lang_npa,
        },
        {
          code: "VIP",
          label: "Клиент VIP+",
        },
      ],
      selectCountry: [
        {
          code: "ru",
          label: "Русский",
        },
        {
          code: "kz",
          label: "Қазақ",
        },
        {
          code: "mn",
          label: "Монгол",
        },
      ],
    };
  },
  // watch: {
  //   locale() {
  //     this.$forceUpdate();
  //     return this.locale;
  //   },
  // },
  methods: {
    setClientType(...args) {
      this.changeClientType(...args);

      if (this.app.tutorialStep) this.changeTutorialStep("vertex-start-edit");
    },
    setCountry(locale) {
      this.changeLocale(locale);

      let bo;

      switch (locale) {
        case "ru":
          bo = 710;
          break;

        case "kz":
          bo = 710;
          break;

        case "mn":
          bo = 710;
          break;

        case "ky":
          bo = 710;
          break;
      }
      localStorage.setItem("locale", locale);
      this.changeCountry(bo);
      this.selectedLocale = locale;

      //this.$router.push({ path: '/kk/'})
      if (this.app.tutorialStep) this.changeTutorialStep("clientType");
    },
  },
};
</script>
