export default (items) => {
  const currenciesTitles = ["RUB", "KGS", "USD"];

  return items
    .map((item) => {
      if (currenciesTitles.includes(item.title)) {
        return {
          title: item.title,
          value: Number(item.value) || null,
        };
      }
    })
    .filter((item) => !!item);
};
