<template>
  <div class="blocks-friends-selector">
    <div class="blocks-friends-selector__title">
      <span
        class="blocks-friends-selector__font blocks-friends-selector__font--title"
      >
        {{ title }}
      </span>

      <div
        class="blocks-friends-selector__counter"
        :class="{ 'blocks-friends-selector__counter--active': !!counter }"
      >
        <span
          class="blocks-friends-selector__font blocks-friends-selector__font--counter"
        >
          {{ counter }}
        </span>
      </div>
    </div>

    <div class="blocks-friends-selector__buttons">
      <button
        v-for="(item, index) in items"
        :key="index"
        class="blocks-friends-selector__button"
        :class="{
          'blocks-friends-selector__button--active':
            selectedItems.includes(item),
        }"
        @click="setSelected(item)"
      >
        <img
          class="blocks-friends-selector__image"
          :src="`/images/icons/friends/${item}.svg`"
          alt="image"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocksFriendsSelector",
  emits: ["select"],
  props: {
    title: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
    initialItems: {
      type: Array,
      default: () => [],
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    counter() {
      return this.selectedItems.length;
    },
  },
  mounted() {
    this.selectedItems = [...this.initialItems];
  },
  methods: {
    setSelected(selectedItem) {
      if (!this.clickable) return;

      const index = this.selectedItems.findIndex(
        (item) => item === selectedItem
      );

      if (index === -1) {
        this.selectedItems.push(selectedItem);
      } else {
        this.selectedItems.splice(index, 1);
      }

      this.$emit("select", this.selectedItems);
    },
    resetSelected() {
      this.selectedItems = [];
    },
  },
};
</script>

<style scoped lang="scss">
.blocks-friends-selector {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__title {
    display: flex;
    align-items: center;
    margin: 0 auto 8px;
    color: $color-dark;
  }

  &__counter {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-light-gray-2;
    color: $color-gray-1;
    margin-left: 4px;

    &--active {
      background-color: $color-dark-blue;
      color: $color-light-purple;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    background-color: transparent;
    border: none;
    opacity: 0.24;
    cursor: pointer;

    &--active {
      opacity: 1;
    }
  }

  &__image {
    height: 60px;

    @media screen and (max-width: 480px) {
      height: 40px;
    }
  }

  &__font {
    &--title {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 24px;
    }

    &--counter {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
