<template>
  <button
    class="slider-navigation-button"
    :class="`slider-navigation-button--direction--${direction}`"
    @click="emitClick"
  >
    <IconsArrowSm class="slider-navigation-button__icon" />
  </button>
</template>

<script>
import IconsArrowSm from "@/components/icons/IconsArrowSm.vue";

export default {
  name: "SliderNavigationButton",
  components: { IconsArrowSm },
  props: {
    direction: {
      type: String,
      default: "right",
      validator(value) {
        return ["left", "right"].includes(value);
      },
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.slider-navigation-button {
  background-color: transparent;
  background-image: url("/images/backgrounds/slider-navigation.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;

  &__icon {
    width: 24px;
    height: 24px;
    color: $color-dark-yellow;
  }

  &--direction {
    &--left {
      rotate: 180deg;
    }
  }
}
</style>
