<template>
  <div @click="openSwitch" :class="{ show: isOpen }">
    <slot name="popup-button" style="color: '#ff0'" />

    <teleport to=".popup-container">
      <div
        :class="[
          {
            show: isOpen,
          },
          'popup-root',
        ]"
        ref="popup"
      >
        <div class="popup">
          <slot name="close-btn" :openSwitch="openSwitch">
            <Button class="close" @click="openSwitch" circle="true">
              <Icon
                name="close"
                color="#000000"
                :height="app.breakpoint.mobile ? '12px' : '16px'"
                :width="app.breakpoint.mobile ? '12px' : '16px'"
              />
            </Button>
          </slot>

          <slot :openSwitch="openSwitch" />
        </div>
      </div>
    </teleport>
  </div>
</template>
<script>
import { Button, Icon } from "@components/UI";
import { useState } from "@src/App.store.js";
import "./Popup.scss";

export default {
  name: "Popup",
  components: {
    Button,
    Icon,
  },
  props: {
    // class: {
    //   default: ""
    // }
  },
  data() {
    return {
      isOpen: false,
      isEmty: true,
    };
  },
  setup() {
    return useState();
  },
  methods: {
    openSwitch() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        if (this.app.currentPopup) this.app.currentPopup.openSwitch();

        this.setcurrentPopup(this);
      } else {
        this.setcurrentPopup(null);
      }
    },
  },
  updated() {
    let container = this.$refs.popup.parentElement;
    let isNonEmty = Boolean(container.querySelector(".popup-root.show"));

    // setTimeout(()=>{
    container.classList.toggle("show", isNonEmty);
    document.body.classList.toggle("disable-app-scroll", isNonEmty);
    // }, this.isOpen ? 0 : 300)
  },
};
</script>
