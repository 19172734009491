<template>
  <LayoutDefault ref="layout" class="pages-products">
    <div class="pages-products__container">
      <component
        ref="section"
        class="pages-products__section"
        :class="`pages-products__section--${sectionComponent}`"
        :is="sectionComponent"
        v-bind="sectionProps"
        @select="onProductsSelect"
        @selectChildren="(items) => setChildren(items, 'firstLevel')"
        @selectChildrenChildren="(items) => setChildren(items, 'secondLevel')"
      />

      <BundlesSectionNavigation
        class="pages-products__navigation"
        :next-title="state.app.locale.redirectPage.navigation.next"
        :prev-title="state.app.locale.redirectPage.navigation.prev"
        @prev="prevSection"
        @next="nextSection"
      />
    </div>
  </LayoutDefault>
</template>

<script>
import { useState } from "@/App.store";
import SectionsProductsSelect from "@/components/sections/products/SectionsProductsSelect.vue";
import SectionsProductsFriends from "@/components/sections/products/SectionsProductsFriends.vue";
import LayoutDefault from "@/layout/LayoutDefault.vue";
import BundlesSectionNavigation from "@/components/bundles/SectionNavigation.vue";

export default {
  name: "ProductsPage",
  components: {
    BundlesSectionNavigation,
    LayoutDefault,
    SectionsProductsSelect,
    SectionsProductsFriends,
  },
  setup() {
    const state = useState();

    return {
      state,
      sections: ["SectionsProductsSelect", "SectionsProductsFriends"],
    };
  },
  data() {
    return {
      products: [],
      children: {
        firstLevel: [],
        secondLevel: [],
      },
      activeSection: 0,
      trees: {},
    };
  },
  watch: {
    children: {
      handler() {
        this.setTrees();
      },
      deep: true,
      immediate: true,
    },
    products: {
      handler() {
        this.setTrees();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    sectionComponent() {
      return this.sections[this.activeSection];
    },
    sectionProps() {
      const props = {};

      switch (this.activeSection) {
        case 0:
          props.productsCount = this.productsCount;
          props.totalPrice = this.totalPrice;
          props.totalPoints = this.totalPersonalPoints;
          props.initialProducts = this.products;

          break;

        case 1:
          props.productsCount = this.productsCount;
          props.totalPrice = this.totalPrice;
          props.personalPoints = this.trees["ABO"].personalPoints;
          props.groupPoints = this.trees["ABO"].groupPoints;
          props.children = this.children;
          props.profitValue = this.trees["VIP"].total;
          props.incomeValue = this.trees["ABO"].total;
          props.categoriesAbo = this.categoriesAbo;
          props.categoriesVip = this.categoriesVip;

          break;
      }

      return props;
    },

    productsCount() {
      return this.products.length;
    },
    totalPrice() {
      return this.products.reduce((sum, item) => sum + item.price, 0);
    },
    totalPersonalPoints() {
      return Math.floor(
        this.products.reduce((sum, item) => sum + item.score, 0)
      );
    },
    totalGroupPoints() {
      return (
        (1 +
          this.children.firstLevel.length +
          this.children.firstLevel.length * this.children.secondLevel.length) *
        this.totalPersonalPoints
      ).toFixed(2);
    },

    categoriesAbo() {
      const categories = [
        {
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.personalActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.amway[
              this.trees["ABO"].details.personalActivity.tooltip
            ],
          value: this.trees["ABO"].details.personalActivity.value,
        },
        {
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.groupActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.amway[
              this.trees["ABO"].details.groupActivity.tooltip
            ],
          value: this.trees["ABO"].details.groupActivity.value,
        },
        {
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.stableBusiness.text
          ],
          tooltip:
            this.state.app.locale.redirectPage[
              this.trees["ABO"].details.stableBusiness.tooltip
            ],
          value: this.trees["ABO"].details.stableBusiness.value,
        },
      ];

      if (this.trees["ABO"].details.leadership.value) {
        categories.push({
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.leadership.text
          ],
          tooltip:
            this.state.app.locale.redirectPage[
              this.trees["ABO"].details.leadership.tooltip
            ],
          value: this.trees["ABO"].details.leadership.value,
        });
      }

      return categories;
    },
    categoriesVip() {
      return [
        {
          name: this.state.app.locale.redirectPage[
            this.trees["VIP"].details.personalActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.loyalty[
              this.trees["VIP"].details.personalActivity.tooltip
            ],
          value: this.trees["VIP"].details.personalActivity.value,
        },
        {
          name: this.state.app.locale.redirectPage[
            this.trees["VIP"].details.groupActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.loyalty[
              this.trees["VIP"].details.groupActivity.tooltip
            ],
          value: this.trees["VIP"].details.groupActivity.value,
        },
      ];
    },
  },
  methods: {
    nextSection() {
      if (this.products.length < 1) return this.openWarningPopup();

      if (this.activeSection >= this.sections.length - 1)
        return this.finishTree();

      this.activeSection++;

      window.scrollTo({ top: 0 });

      if (this.activeSection) {
        this.$refs.layout.setFooterText("friends");
      } else {
        this.$refs.layout.setFooterText("products");
      }
    },
    prevSection() {
      if (this.activeSection <= 0) return this.$router.push({ path: "/" });

      this.activeSection--;

      window.scrollTo({ top: 0 });

      if (this.activeSection) {
        this.$refs.layout.setFooterText("friends");
      } else {
        this.$refs.layout.setFooterText("products");
      }
    },

    openWarningPopup() {
      this.$popup.open("warning", {
        title: this.state.app.locale.redirectPage.errors.selectOne,
      });
    },

    setChildren(items, level) {
      this.children[level] = [...items];
    },
    onProductsSelect(products) {
      this.products = [...products];
    },

    setTree(clientType) {
      const { tree } = this.state.generateTree({
        children: {
          firstLevel: this.children.firstLevel.length,
          secondLevel: this.children.secondLevel.length,
        },
        points: Math.floor(this.totalPersonalPoints),
        clientType,
      });

      this.trees[clientType] = this.state.getTreeParams(tree);
    },
    setTrees() {
      this.setTree("ABO");
      this.setTree("VIP");
    },

    finishTree() {
      this.$router.push({ path: "/income" });
    },
  },
};
</script>

<style scoped lang="scss">
.pages-products {
  &__container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 75px 36px 24px;

    @media screen and (max-width: 480px) {
      padding: 68px 16px 24px;
      max-width: 375px;
    }
  }

  &__section {
    width: 100%;
    margin: 0 auto;

    &--SectionsProductsSelect {
      @media screen and (min-width: 480px) {
        max-width: 343px;
      }
    }

    &--SectionsProductsFriends {
      @media screen and (min-width: 480px) {
        max-width: 552px;

        padding-top: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
