export default {
  _code: "mn",
  PPV: "Хувийн",
  GPV: "Багийн",
  startTitle: "Amway тооцоолуур",
  invitationSelectCountry: "",
  invitationSelectClientType:
    "Amway түншлэлийн төрлөө сонгоно уу. Амвэй бизнес эрхлэгч эсвэл VIP+ хэрэглэгч",
  income_label: "Орлого",
  rules_text: "Хэрэглэх нөхцөл",
  example_calc_for: "Тооцооллын жишээ",

  group_points_title_vertexEdit: "Багийн <br /> оноо",
  total: "Нийт",
  personal_friends_buy:
    "+ Хувийн худалдан авалт болон найз нөхдийн худалдан авалтад зориулагдсан",

  upload_title: "Таны хадгалсан тооцооллууд",
  upload_calc: "Тооцоолол",
  upload_calc_cost: "дүнгээр",

  buttons: {
    settings: "Тохиргоо",
    new_calc: "Шинэ тооцоолол",

    save_calc: "Тооцооллыг хадгалах",
    save_calc_success: "Тооцооллыг амжилттай хадгаллаа!",

    upload_calc: "Тооцооллыг татаж авах",
    download_calc: "Тооцооллыг татаж авах",
    instructions: "Ашиглах заавар",
    start_learn: "Сургалтанд хамрагдах",
    exit: "Гарах",

    edit: "Мэдээллийг <br /> засах ",

    skip_tutorial: "Хичээлийг алгасах",
    continue: "Үргэлжлүүлэх",
  },

  inputs: {
    personal_points: "Хувийн оноо",
    client_value: "Хэрэглэгчийн оноо",
  },

  checkboxes: {
    guardianship: "Дэмжигч спонсорын урамшуулал",
    guardianship_branch: "Итгэмжлэгдсэн салбар",
    bronze_start: "Хүрэл эхлэл+",
    bronze_start_mobile: "BS+",
    bronze_bonus: "Хүрэл цолны урамшуулал+ ",
    bronze_bonus_mobile: "BV+",
  },

  listTitles: {
    personal_achievements: "Хувийн амжилтын төлөө",
    personal_percent_difference: "%-ийн зөрүүнд",
    retail_margin: "найздаа хэл",
    leader_reward: "Манлайлалд",
    sponsor_reward: "Дэмжигч спонсорын",
    ruby_reward: "Рубин цолны",
    pearl_reward: "Сувдан цолны",
    amway_total: "Amway төлөвлөгөөний дагуу Нийт",
    personal_sells_amount:
      "Хувийн борлуулалтын нийт хэмжээнд бодогдох нийт дүн",
    personal_friends_buy:
      "Хувийн болон найз нөхдийн худалдан авалтанд бодогдох нийт дүн",
    loyalty_program: "Үнэнч байдлын хөтөлбөрийн дагуу Нийт",

    friends_discount: "Хэрэглэгчийн худалдан авалтын хөнгөлөлт+",
    bronze_start: "Хүрэл эхлэл+",
    bronze_reward: "Хүрэл цолны урамшуулал+",
    elite_plus: "Элит+",
    business_plus_total: "Бизнес Нэмэх+ дагуу Нийт",
  },

  unsave: {
    title: "Хадгалаагүй тооцоолол.",
    text: "Сургалтанд хамрагдахад таны одоогийн тооцоолол арилна. Та сургалтанд хамрагдахаасаа өмнө дуусаагүй тооцооллоо хадгалах боломжтой.",
  },

  tutorial: {
    clientType_title: "Amway тооцоолуурт тавтай морилно уу!",
    clientType_text: " Хэрэглэгчдийн төрлөөс сонгоно уу.",

    country_text: "Цахим хуудасны хэл",

    "vertex-start-edit": `"Тойрог" бүр нь Хувийн оноо, Багийн оноо, хувийн урамшууллын хэмжээг багтаана. <br /> <br /> Засварлах цэс рүү орохын тулд "тойрог"-ын төв эсвэл олон цэг дээр дарна уу`,

    "vertex-edit-title": "Засварлах цэс",
    "vertex-edit-1-text":
      "Хувийн оноогоо 10-аар нэмэгдүүлэхийн тулд + дээр дарах эсвэл гараар оруулна уу.",
    "vertex-edit-2-text":
      "Хэрэглэгчийн оноог 10-аар нэмэгдүүлэхийн тулд + дээр дарах эсвэл гараар оруулна уу.",

    "vertex-personal-turnover-up-title": "Хувийн эргэлт өссөн",
    "vertex-personal-turnover-up-text":
      " Хувийн оноог мөн багийн оноо болон урамшууллын хэмжээнд тусгасан болно.",

    "vertex-ext-menu-title": "Нэмэлт цэс",
    "vertex-ext-menu-text":
      "Тойргийн баруун дээд буланд байгаа дүрс дээр дарж нэмэлт цэс нээнэ үү",

    "vertex-add-partner-title": "Түнш нэмэх",
    "vertex-add-partner-text":
      "Өөрийн багтаа шинэ түнш нэмэхийн тулд «нэмэх» дээр дарна уу.",

    "vertex-add-partner-2-title": "Дахиад нэгийг нэмье",
    "vertex-add-partner-2-text": "Багийн оноог өсгөхөд анхаарал хандуулаарай.",

    "vertex-add-partner-3-title": "Бидний зорилго - 3 түнш",
    "vertex-add-partner-3-text":
      "Эхний шугамд байрласан гурван гол түнш бол сайн эхлэл юм.",

    "vertex-hide-tree-title": "«Шугам»-ыг буулгах эсвэл хураах",
    "vertex-hide-tree-text":
      "Эхний шугамд байрласан гурван гол түнш бол сайн эхлэл юм.",

    "vertex-view-detail-info-title": "Нарийвчилсан мэдээллийг үзэх",
    "vertex-view-detail-info-text":
      "Түншийн «тойрог» төв дээр дарж засварлах цэс рүү орно.",

    "accordion-expand-helper-title": "Дэлгэрэнгүй мэдээлэл",
    "accordion-expand-helper-text":
      "«Amway төлөвлөгөөний дагуу» болон «Бизнес+ дагуу НИЙТ» хавтасыг нээх, үүний тулд эдгээр хавтас дээр дарахад л хангалттай.",

    "accordion-collapse-helper-title": "Хавтасыг хураах",
    "accordion-collapse-helper-text": "«Хураах» товчлуур дээр дарж хураана уу.",

    "canvas-move-helper-title": "Зөөх",
    "canvas-move-helper-text": "Дэлгэцээр чөлөөтэй зөөж болно",

    "canvas-zoom-helper-title": "Масштаб",
    "canvas-zoom-helper-text": "Мөн томруулж эсвэл жижигрүүлнэ.",

    "settings-open-title": "Тооцоолуурын цэс",
    "settings-open-text":
      "Тооцоолуурын нэмэлт функцууд баруун талын цэсэнд оршино.",

    "settings-popup-title": "Нэмэлт функцууд ",
    "settings-popup-text":
      "Энэ цонхонд та дараа нь буцааж нээх боломжтойгоор тооцооллоо хадгалах, өмнө нь хийсэн тооцооллыг хэрэглэгчдэд үзүүлэх зорилгоор ачаалах, мөн өөр өөр орлогын тооцооллын урьдчилсан тохиргоог ашиглах боломжтой.",

    "end-title": "Баяр хүргэе!",
    "end-text": "Амжилттай бизнесийг бий болго.",
  },

  currency_symbol: "₸",
  accord_hide: "Хураах",
  lang_npa: "АБЭ ",
  enter_name: "Нэрээ оруулна уу ",
  lang_vip: "VIP+",
  instruction: "/Amway_Calculator_instruction_MN_1.pdf",
  copyrate: `© 2011-${new Date().getFullYear()} Amway Europe. Бүх эрх хуулиар хамгаалагдсан.`,
  clientTypeOptions: [
    {
      code: "ABO",
      label: "АБЭ",
    },
    {
      code: "VIP",
      label: "VIP+ Хэрэглэгч",
    },
  ],
  duplicate: "Даган дуурайх",
  redirectPage: {
    routes: {
      product: "Бүтээгдэхүүн",
      discount: "Хөнгөлөлт",
      income: "Орлого",
    },
      how_works:'Тооцоолуур хэрхэн ажилладаг вэ?',
      how_works_link:'https://vimeo.com/861179664',
    description:
      "Түншүүдийн боломжит төлбөр, ашиг тусын үр дүн болон Amway үйлчлүүлэгчид ойролцоогоор, гэхдээ&nbsp;БИШ&nbsp;баталгаатай орлого. Тооцоолсон орлого хувийн худалдан авалтын хэмжээ, хэрэглэгчийн худалдан авалтын хэмжээ, бүтээлээс хамаарна өөрийн түншүүдийн бүлэг.",
    navigation: {
      next: "Цаашид",
      prev: "Буцах",
    },
    add: "Нэмэх",
    remove: "Устгах",
    totalPrice: "Нийт үнэ",
    pricePerDay: "Өдрийн үнэ*",
    personalPoints: "Хувийн оноо",
    groupPoints: "Бүлэг оноо",
    total: "Нийт",
    products: "Бараа",

    potentialIncome: "Түншийн боломжит орлого",
    profit: "Худалдан авалтын өгөөж",
    client: "Худалдан авагчид",
    vipClient: "VIP худалдан авагчид",
    friends: "Таны найзууд",
    friendsFriend: "Танил найзууд",
    errors: {
      selectOne: "Үргэлжлүүлэхийн тулд дор хаяж нэг бүтээгдэхүүн сонгоно уу",
    },
    understandable: "Энэ нь тодорхой байна",
    points: "Оноо",
    forPersonalActivity: "Хувийн үйл ажиллагаанд зориулагдсан",
    loyalty: {
      tooltipPersonalActivity:
        "Үнэнч байдлын хөтөлбөрийн хүрээнд гаргасан хувийн амжилтын нэг урамшуулал",
      tooltipGroupActivity:
        "Бейілділік бағдарламасы бойынша % айырмашылығы үшін",
    },
    amway: {
      tooltipPersonalActivity:
        "Amway төлөвлөгөөний дагуу хувийн амжилтад зориулсан нэг удаагийн урамшуулал",
      tooltipGroupActivity: "Amway төлөвлөгөөний дагуу% -ийн зөрүү",
    },

    forGroupActivity: "Бүлгийн үйл ажиллагаанд зориулагдсан",

    monthDiscount: "Сарын нийт хөнгөлөлт",
    forStableBusiness: "Тогтвортой бизнесийн хувьд",
    tooltipStableBusiness: "Business Plus+",
    forGuardianship: "Менторын хувьд",
    tooltipGuardianship: "Манлайлал, Ruby, Pearl төлбөрийн хувьд",
    monthPayments: "Сарын нийт төлбөр",
    profitText:
      "<strong>Хэрэглэгчийн VIP+</strong> - өөртөө&nbsp;өөрсдөө бүтээгдэхүүн худалдан авч, найз нөхөд,&nbsp;танил хүмүүстээ санал болгож, компанийн үйлчлүүлэгч болоход нь тусалдаг. Төрөл бүрийн хөтөлбөрүүдийн хөнгөлөлтийн дансанд нэмэлт хөнгөлөлт авдаг.",
    incomeText:
      "<strong>Amway-н түнш</strong> — Amway Казахстан ХХК-тай гэрээ байгуулсан хуулийн этгээдийн статустай (IE/LLP). Эцсийн хэрэглэгчдэд бүтээгдэхүүнээ борлуулж, түншийн сүлжээг хөгжүүлэх боломжтой. Үүнийхээ төлбөрийг тэрээр өөрийн харилцах дансандаа авдаг.",
    footerTextOne:
      "* Өдрийн үнийг мэдээллийн зорилгоор өгсөн бөгөөд нэг сарын хэрэглээнд үндэслэнэ. Энэ нь төлбөр биш юм. Худалдан авахдаа барааны бүрэн өртгийг төлдөг.",
    footerTextTwo:
      "Хүлээн авсан үр дүн нь ойролцоо боловч ҮГҮЙ баталгаатай орлого. <br /> Тооцоолсон орлого нь худалдан авалтын хувийн хэмжээ, худалдан авалтын хэмжээ зэргээс хамаарна үйлчлүүлэгчид, өөрийн түншүүдийн бүлгийг бий болгох.",
    langSelectTitle: "Хэлний сонголт",
      langSelect_ru: "Русский",
      langSelect_kz: "Қазақ",
      langSelect_mn: "Монгол",
      langSelect_ky: "Кыргызча",
    currencySelectTitle: "Валютын сонголт",
      currencySelect_ru: "Рубли",
      currencySelect_kz: "Тенге",
      currencySelect_mn: "Төгрөг",
      currencySelect_ky: "Сом",
    currencySelectDescription:
      "Казахстаны тенгенээс бусад мөнгөн тэмдэгтээр илэрхийлэгдсэн үнэ нь ойролцоо утга бөгөөд бусад валютын харилцах ханшаар хөрвүүлж тооцно. Түнш болон Хэрэглэгчдийн төлбөрийг зөвхөн тенгегээр тооцож олгодог.",
  },
};
