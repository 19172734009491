export default {
    _code: "ky",
    currency_symbol: "₸",
    PPV: "Жеке ",
    GPV: "Топтук ",
    startTitle: "Amway калькулятору",
    invitationSelectCountry: "",
    invitationSelectClientType:
        "Amway менен өнөктөштүктүн түрүн тандаңыз. Көз карандысыз ишкер же VIP+ кардары",
    income_label: "Киреше",
    rules_text: "Пайдалануу шарттары",
    example_calc_for: "үчүн эсептөө мисалы",

    group_points_title_vertexEdit: "Топтук  <br />упайлар",
    total: "Жыйынтыкта",
    personal_friends_buy: "+ Жеке сатып алуулар жана достордун сатып алуулары үчүн",

    upload_title: "Сакталган эсептөөлөрүңүз",
    upload_calc: "Эсептөө",
    upload_calc_cost: "суммага",

    buttons: {
        settings: "Орнотуулар",
        new_calc: "Жаңы эсептөө",

        save_calc: "Эсепти сактоо",
        save_calc_success: "Эсептөө ийгиликтүү сакталды!",

        upload_calc: "Эсептөөнү жүктөө",
        download_calc: "Эсептөө жүктөө",
        instructions: "Нускама",
        start_learn: "Окууну өтүү",
        exit: "Чыгуу",

        edit: "Маалыматты <br />түзөтүү",

        skip_tutorial: "Окуу куралын өткөрүп жиберүү",
        continue: "Улантуу",
    },

    inputs: {
        personal_points: "Жеке упайлар",
        client_value: "Кардар упайлары",
    },

    checkboxes: {
        guardianship: "Камкордук акысы",
        guardianship_branch: "Ишенимдүү филиал",
        bronze_start: "Бронзалык Старт+",
        bronze_start_mobile: "БС+",
        bronze_bonus: "Бронзалык төлөм+",
        bronze_bonus_mobile: "БВ+",
    },

    listTitles: {
        personal_achievements: "Жеке жетишкендиктери үчүн",
        personal_percent_difference: "%-дык айырма үчүн",
        retail_margin: "Досуна айт",
        leader_reward: "Лидерлик үчүн",
        sponsor_reward: "Ишенимдүү",
        ruby_reward: "Рубиналык",
        pearl_reward: "Жемчуг үчүн",
        amway_total: "Жалпы Аmway планы боюнча",
        personal_sells_amount: "Жеке сатуунун жалпы көлөмү",
        personal_friends_buy: "Жалпы Жеке сатып алуулар жана достордун сатып алуулары үчүн",
        loyalty_program: "Жалпы Лоялдуулук Программасы боюнча",

        friends_discount: "Кардардын сатып алуулары үчүн арзандатуулар+",
        bronze_start: "Бронзалык Старт+",
        bronze_reward: "Бронзалык төлөм+",
        elite_plus: "Элит+",
        business_plus_total: "Жалпы Бизнес Плюс+ боюнча",
    },

    unsave: {
        title: "Сакталбаган эсептөө.",
        text: "Тренингге барганда учурдагы эсебиңиз өчүрүлөт. Сиз окуу куралын алуудан мурун бүтпөгөн эсебиңизди сактай аласыз.",
    },

    tutorial: {
        clientType_title: "Добро пожаловать в Калькулятор Amway!",
        clientType_text: " Выберите любой тип Клиента.",

        country_text: "Язык отображения сайта",

        "vertex-start-edit": `Каждый «круг» содержит Личные баллы, Групповые баллы и размер личного бонуса. <br /> <br /> Нажмите на центр «круга» или многоточие, чтобы войти в меню редактирования.`,

        "vertex-edit-title": "Меню редактирования",
        "vertex-edit-1-text":
            "Нажмите на +, чтобы увеличить количество Личных баллов на 10 или введите вручную.",
        "vertex-edit-2-text":
            "Нажмите на +, чтобы увеличить количество Баллов клиентов на 10 или введите вручную.",

        "vertex-personal-turnover-up-title": "Личный оборот вырос",
        "vertex-personal-turnover-up-text":
            " личных баллов также отразились в групповых баллах и размере бонуса.",

        "vertex-ext-menu-title": "Дополнительное меню",
        "vertex-ext-menu-text":
            "Нажмите на иконку в правом верхнем углу круга, чтобы открыть дополнительное меню",

        "vertex-add-partner-title": "Добавление партнера",
        "vertex-add-partner-text":
            "Нажмите на «плюс», чтобы добавить нового партнера к вашей группе.",

        "vertex-add-partner-2-title": "Добавим еще одного",
        "vertex-add-partner-2-text":
            "Обратите внимание на увеличение групповых баллов.",

        "vertex-add-partner-3-title": "Наша цель – 3 партнера",
        "vertex-add-partner-3-text":
            "Три ключевых партнера в первой линии это хороший старт.",

        "vertex-hide-tree-title": "Свернуть или развернуть «Ветку»",
        "vertex-hide-tree-text":
            "Три ключевых партнера в первой линии это хороший старт.",

        "vertex-view-detail-info-title": "Просмотр подробной информации",
        "vertex-view-detail-info-text":
            "Нажмите на центр «круга» партнера, чтобы войти в меню редактирования.",

        "accordion-expand-helper-title": "Подробные данные",
        "accordion-expand-helper-text":
            "Раскройте блоки «По плану Amway» и «Итого по Бизнес+». Для этого просто нажмите на данные блоки.",

        "accordion-collapse-helper-title": "Сверните блоки",
        "accordion-collapse-helper-text":
            "Сверните блоки нажав на кнопку «Свернуть».",

        "canvas-move-helper-title": "Перемещение",
        "canvas-move-helper-text": "Вы можете свободно перемещаться по экрану.",

        "canvas-zoom-helper-title": "Масштаб",
        "canvas-zoom-helper-text": "А также увеличить или уменьшить масштаб.",

        "settings-open-title": "Меню Калькулятора",
        "settings-open-text":
            "Дополнительные функции Калькулятора находятся в меню справа.",

        "settings-popup-title": "Дополнительные функции ",
        "settings-popup-text":
            "В этом окне вы можете сохранить расчет, чтобы вернуться к нему позже, загрузить созданные ранее расчеты для демонстрации клиентам, а также пользоваться пресетами расчетов на разные суммы дохода.",

        "end-title": "Поздравляем!",
        "end-text": "Стройте успешный бизнес.",
    },

    accord_hide: "Жыйыштыруу",
    lang_npa: "АКИ",
    enter_name: "Атыңызды жазыңыз",
    lang_vip: "VIP+",
    instruction: "/Amway_Calculator_instruction_RU_1.pdf",
    copyrate: `© 2011-${new Date().getFullYear()} Amway Europe. Все права защищены.`,
    clientTypeOptions: [
        {
            code: "ABO",
            label: "АКИ",
        },
        {
            code: "VIP",
            label: "VIP+ кардар",
        },
    ],
    duplicate: "Кайталоо",
    redirectPage: {
        routes: {
            product: "Продукт",
            discount: "Арзандатуулар",
            income: "Киреше",
        },
        how_works:'Как работает Калькулятор?',
        how_works_link:'https://vimeo.com/861179664',
        description: "Amway өнөктөштөрүнүн жана кардарларынын потенциалдуу төлөмдөрүнүн жана пайдаларынын натыйжалары болжолдуу, бирок кирешенин кепилденген суммасы ЭМЕС. Өнөктөштөрдүн жана кардарлардын потенциалдуу төлөмдөрүнүн жана артыкчылыктарынын натыйжалары Эсептелген киреше сатып алуулардын жеке көлөмүнө, кардарлардын сатып алууларынын көлөмүнө, өнөктөштөрдүн өз тобун түзүүгө жараша болот.",
        navigation: {
            next: "Андан ары",
            prev: "Артка",
        },
        add: "Кошуу",
        remove: "Жок кылуу",
        totalPrice: "Жалпы баасы",
        pricePerDay: "Күнүнө баасы*",
        personalPoints: "Жеке упайлар",
        groupPoints: "Топтук упайлар",
        total: "Бардыгы",
        products: "Товарлар",
        potentialIncome: "Өнөктөштүн потенциалдуу кирешеси",
        profit: "Соода кылуудан пайдаңыз",
        client: "Сатып алуучулар",
        vipClient: "ВИП сатып алуучулар",
        friends: "Сенин досторуң",
        friendsFriend: "Тааныш достор",
        errors: {
            selectOne: "Улантуу үчүн жок дегенде бир продукт тандаңыз",
        },
        understandable: "Түшүнүктүү",
        points: "Упайлар",
        forPersonalActivity: "Жеке иш үчүн",
        loyalty: {
            tooltipPersonalActivity:
                "Лоялдуулук программасы боюнча жеке жетишкендиктер үчүн бир бонус",
            tooltipGroupActivity: "Лоялдуулук программасы боюнча % айырмасы үчүн",
        },
        amway: {
            tooltipPersonalActivity:
                "Amway планы боюнча жеке жетишкендиктер үчүн бир бонус",
            tooltipGroupActivity: "Amway планы боюнча % айырмасы үчүн",
        },

        forGroupActivity: "Топтук иш үчүн",

        monthDiscount: "Айына&nbsp;жалпы арзандатуу",
        forStableBusiness: "Туруктуу бизнес үчүн",
        tooltipStableBusiness: "Business Plus+",
        forGuardianship: "Насаатчылык үчүн",
        tooltipGuardianship: "Лидерлик, Рубиналык, Жемчуг төлөмдөрү үчүн",
        monthPayments: "Айына жалпы төлөм",
        profitText:
            "<strong>Кардар VIP+</strong> - өзү үчүн&nbsp;өнүмдөрдү сатып алат жана досторуна жана&nbsp;тааныштарына сунуштайт, аларга компаниянын кардары болууга жардам берет. Ар кандай программалар үчүн эсептик эсепке кошумча арзандатууларды алат.",
        incomeText:
            '<strong>Amway Өнөктөшү</strong> — "Amway Kazakhstan" ЖЧКсы менен Келишим түзгөн юридикалык жак (ЖК/ЖПК) статусуна ээ. Акыркы кардарларга өнүмдөрдү кайра сата алат жана өнөктөш тармагын өнүктүрө алат. Бул үчүн төлөмдөрдү өзүнүн учурдагы эсебине алат.',
        footerTextOne:
            "* Күнүнө баа маалымат максатында жана бир ай колдонуунун негизинде берилет. Бул бөлүп төлөө эмес. Товардын толук наркы сатып алуу учурунда төлөнөт.",
        footerTextTwo:
            "Алынган натыйжалар болжолдуу, бирок ЭМЕС кепилденген киреше. <br /> Эсептелген киреше сатып алуулардын жеке көлөмүнө, сатып алуулардын көлөмүнө жараша болот кардарлар, өнөктөштөрдүн өз тобун түзүү.",
        langSelectTitle: "Тил тандоо",
        langSelect_ru: "Русский",
        langSelect_kz: "Қазақ",
        langSelect_mn: "Монгол",
        langSelect_ky: "Кыргызча",
        currencySelectTitle: "Валюта тандоо",
        currencySelect_ru: "Рубли",
        currencySelect_kz: "Тенге",
        currencySelect_mn: "Тугрик",
        currencySelect_ky: "Сом",


        currencySelectDescription:
            "Казакстандык теңгеден (₸) башка валютада көрсөтүлгөн баалар болжолдуу жана Казакстан Республикасынын Улуттук банкынын эсептөө күнүнө карата курсу боюнча эсептелет. Amway өнөктөштөрүнүн жана кардарларынын потенциалдуу төлөмдөрү жана жеңилдиктери теңге менен гана эсептелет жана төлөнөт.",
    },
};
