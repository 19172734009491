export default {
    _code: "ru",
    PPV: "Личные",
    GPV: "Групповые",
    startTitle: "Калькулятор Amway",
    invitationSelectCountry: "",
    invitationSelectClientType:
        "Выберите вид партнерства с Amway. Независимый Предприниматель или Клиент VIP+",
    income_label: "Доход",
    rules_text: "Правила использования",
    example_calc_for: "Пример расчета на",

    group_points_title_vertexEdit: "Групповые <br /> баллы",
    total: "Итого",
    personal_friends_buy: "+ За личные покупки и покупки друзей",

    upload_title: "Ваши сохраненные расчеты",
    upload_calc: "Расчёт",
    upload_calc_cost: "на сумму",

    buttons: {
        settings: "Настройки",
        new_calc: "Новый расчет",

        save_calc: "Сохранить расчет",
        save_calc_success: "Расчет успешно сохранен!",

        upload_calc: "Загрузить расчет",
        download_calc: "Скачать расчет",
        instructions: "Инструкция",
        start_learn: "Пройти обучение",
        exit: "Выйти",

        edit: "Редактировать <br /> информацию",

        skip_tutorial: "Пропустить обучение",
        continue: "Продолжить",
    },

    inputs: {
        personal_points: "Личные баллы",
        client_value: "Баллы клиентов",
    },

    checkboxes: {
        guardianship: "Попечительское вознаграждение",
        guardianship_branch: "Попечительская ветвь",
        bronze_start: "Бронзовый Старт+",
        bronze_start_mobile: "БС+",
        bronze_bonus: "Бронзовая Выплата+",
        bronze_bonus_mobile: "БВ+",
    },

    listTitles: {
        personal_achievements: "За личные достижения",
        personal_percent_difference: "За разницу в %-ах",
        retail_margin: "Расскажи другу",
        leader_reward: "За Лидерство",
        sponsor_reward: "Попечительская",
        ruby_reward: "Рубиновая",
        pearl_reward: "Жемчужная",
        amway_total: "Итого по Плану Amway",
        personal_sells_amount: "Итого за персональный объем продаж",
        personal_friends_buy: "Итого за личные покупки и покупки друзей",
        loyalty_program: "Итого по Программе Лояльности",

        friends_discount: "Скидки за покупки клиентов+",
        bronze_start: "Бронзовый Старт+",
        bronze_reward: "Бронзовая Выплата+",
        elite_plus: "Элит+",
        business_plus_total: "Итого по Бизнес Плюс+",
    },

    unsave: {
        title: "Несохраненный расчет.",
        text: "При переходе к обучению ваш текущий расчет сотрется. Вы можете сохранить свой незавершенный расчет перед тем, как пройти обучение.",
    },

    tutorial: {
        clientType_title: "Добро пожаловать в Калькулятор Amway!",
        clientType_text: " Выберите любой тип Клиента.",

        country_text: "Язык отображения сайта",

        "vertex-start-edit": `Каждый «круг» содержит Личные баллы, Групповые баллы и размер личного бонуса. <br /> <br /> Нажмите на центр «круга» или многоточие, чтобы войти в меню редактирования.`,

        "vertex-edit-title": "Меню редактирования",
        "vertex-edit-1-text":
            "Нажмите на +, чтобы увеличить количество Личных баллов на 10 или введите вручную.",
        "vertex-edit-2-text":
            "Нажмите на +, чтобы увеличить количество Баллов клиентов на 10 или введите вручную.",

        "vertex-personal-turnover-up-title": "Личный оборот вырос",
        "vertex-personal-turnover-up-text":
            " личных баллов также отразились в групповых баллах и размере бонуса.",

        "vertex-ext-menu-title": "Дополнительное меню",
        "vertex-ext-menu-text":
            "Нажмите на иконку в правом верхнем углу круга, чтобы открыть дополнительное меню",

        "vertex-add-partner-title": "Добавление партнера",
        "vertex-add-partner-text":
            "Нажмите на «плюс», чтобы добавить нового партнера к вашей группе.",

        "vertex-add-partner-2-title": "Добавим еще одного",
        "vertex-add-partner-2-text":
            "Обратите внимание на увеличение групповых баллов.",

        "vertex-add-partner-3-title": "Наша цель – 3 партнера",
        "vertex-add-partner-3-text":
            "Три ключевых партнера в первой линии это хороший старт.",

        "vertex-hide-tree-title": "Свернуть или развернуть «Ветку»",
        "vertex-hide-tree-text":
            "Три ключевых партнера в первой линии это хороший старт.",

        "vertex-view-detail-info-title": "Просмотр подробной информации",
        "vertex-view-detail-info-text":
            "Нажмите на центр «круга» партнера, чтобы войти в меню редактирования.",

        "accordion-expand-helper-title": "Подробные данные",
        "accordion-expand-helper-text":
            "Раскройте блоки «По плану Amway» и «Итого по Бизнес+». Для этого просто нажмите на данные блоки.",

        "accordion-collapse-helper-title": "Сверните блоки",
        "accordion-collapse-helper-text":
            "Сверните блоки нажав на кнопку «Свернуть».",

        "canvas-move-helper-title": "Перемещение",
        "canvas-move-helper-text": "Вы можете свободно перемещаться по экрану.",

        "canvas-zoom-helper-title": "Масштаб",
        "canvas-zoom-helper-text": "А также увеличить или уменьшить масштаб.",

        "settings-open-title": "Меню Калькулятора",
        "settings-open-text":
            "Дополнительные функции Калькулятора находятся в меню справа.",

        "settings-popup-title": "Дополнительные функции ",
        "settings-popup-text":
            "В этом окне вы можете сохранить расчет, чтобы вернуться к нему позже, загрузить созданные ранее расчеты для демонстрации клиентам, а также пользоваться пресетами расчетов на разные суммы дохода.",

        "end-title": "Поздравляем!",
        "end-text": "Стройте успешный бизнес.",
    },

    currency_symbol: "₸",
    accord_hide: "Свернуть",
    lang_npa: "НПА",
    enter_name: "Введите имя",
    lang_vip: "VIP+",
    instruction: "/Amway_Calculator_instruction_RU_1.pdf",
    copyrate: `© 2011-${new Date().getFullYear()} Amway Europe. Все права защищены.`,
    clientTypeOptions: [
        {
            code: "ABO",
            label: "НПА",
        },
        {
            code: "VIP",
            label: "Клиент VIP+",
        },
    ],
    duplicate: "Дуплицировать",
    redirectPage: {
        routes: {
            product: "Продукт",
            discount: "Скидки",
            income: "Доход",
        },
        how_works:'Как работает Калькулятор?',
        how_works_link:'https://vimeo.com/861179664',
        description:
            "Полученные&nbsp;результаты&nbsp;потенциальных&nbsp;выплат и выгод Партнеров и Клиентов Amway представляют собой приблизительный, но&nbsp;НЕ&nbsp;гарантированный размер дохода. Рассчитанный доход зависит от личного объема покупок, объема покупок клиентов, создания собственной группы партнеров.",
        navigation: {
            next: "Далее",
            prev: "Назад",
        },
        add: "Добавить",
        remove: "Удалить",
        totalPrice: "Итоговая цена",
        pricePerDay: "Цена в день*",
        personalPoints: "Личные баллы",
        groupPoints: "Групповые баллы",
        total: "Итого",
        products: "Товары",
        potentialIncome: "Потенциальный доход партнера",
        profit: "Выгода от покупок",
        client: "Покупатели",
        vipClient: "VIP-Покупатели",
        friends: "Ваши друзья",
        friendsFriend: "Знакомые друзей",
        errors: {
            selectOne: "Выберите хотя бы один товар, чтобы перейти далее",
        },
        understandable: "Понятно",
        points: "Баллы",
        forPersonalActivity: "За личную активность",
        loyalty: {
            tooltipPersonalActivity:
                "Единый бонус и за личные достижения по Программе Лояльности",
            tooltipGroupActivity: "За разницу в %-тах по Программе Лояльности",
        },
        amway: {
            tooltipPersonalActivity:
                "Единый бонус и за личные достижения по Плану Amway",
            tooltipGroupActivity: "За разницу в %-тах по Плану Amway",
        },
        forGroupActivity: "За групповую активность",
        monthDiscount: "Итого скидка за&nbsp;месяц",
        forStableBusiness: "За стабильный бизнес",
        tooltipStableBusiness: "Бизнес Плюс+",
        forGuardianship: "За наставничество",
        tooltipGuardianship: "За Лидерство, Рубиновая, Жемчужная выплаты",
        monthPayments: "Итого выплата за месяц",
        profitText:
            "<strong>Клиент VIP+</strong> — покупает продукцию для&nbsp;себя, и рекомендует своим друзьям и&nbsp;знакомым, помогает им стать клиентами компании. Получает дополнительные скидки на скидочный счет по различным программам.",
        incomeText:
            "<strong>Партнер Amway</strong> — имеет статус юридического лица (ИП/TОО), который заключил Договор с ТОО «Эмвэй Казахстан». Может перепродавать продукцию конечным клиентам и развивать партнерскую сеть. Получает за это выплаты на свой расчетный счет.",
        footerTextOne:
            "* Цена в день приведена в ознакомительных целях и с расчетом на месяц использования. Не является рассрочкой. Полная стоимость товара оплачивается в момент покупки.",
        footerTextTwo:
            "Полученные результаты представляют собой приблизительный, но НЕ гарантированный размер дохода. <br /> Рассчитанный доход зависит от личного объема покупок, объема покупок клиентов, создания собственной группы партнеров.",
        langSelectTitle: "Выбор языка",
        langSelect_ru: "Русский",
        langSelect_kz: "Қазақ",
        langSelect_mn: "Монгол",
        langSelect_ky: "Кыргызча",
currencySelectTitle: "Выбор валюты",
        currencySelect_ru: "Рубли",
        currencySelect_kz: "Тенге",
        currencySelect_mn: "Тугрик",
        currencySelect_ky: "Сом",
    currencySelectDescription:
"Цены, указанные в валюте, отличной от казахстанского тенге (₸), носят приблизительный характер и рассчитываются по кросс-курсу национальных валют к доллару США.​ Потенциальные выплаты и выгоды Партнеров и Клиентов Amway рассчитываются и выплачиваются только в тенге.",
},
};
