<template>
  <header class="layout-header">
    <a href="/">
      <img class="layout-header__logo" src="/images/logo.svg" alt="logo" />
    </a>
  </header>
</template>

<style scoped lang="scss">
.layout-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border-bottom: 1px solid rgba(#04305e, 0.2);
}
</style>
