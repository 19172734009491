import { calcPearlSum, getPearlItems } from "@/utils/helpers/pearl";

export default (tree, bo) => {
  const { pearlChildren, fullPearlAmount, hasPearl } = getPearlItems(tree);

  if (hasPearl) {
    let sum = pearlChildren.reduce(
      (currentValue, item) => (currentValue += calcPearlSum(item, true, false)),
      0
    );

    if (!fullPearlAmount) {
      const lowPearlItems = pearlChildren.filter(
        (item) => item.group_points < 10000
      );

      const mg = lowPearlItems.reduce((value, item) => {
        return value + 10000 - item.group_points;
      }, 0);

      return sum * bo * 0.01 - mg * 710 * 0.01;
    } else {
      return sum * bo * 0.01;
    }
  } else {
    return 0;
  }
};
