<template>
  <footer class="layout-footer">
    <div class="layout-footer__container">
      <UiSelectButton
        class="layout-footer__button"
        :class="{
          'layout-footer__button--currencyKGS':
            state.app.currency.symbol === 'KGS',
        }"
        @click="selectCurrency"
      >
        {{ state.app.currency.symbol }}
      </UiSelectButton>

      <div class="layout-footer__text">
        <span
          class="layout-footer__font layout-footer__font--text"
          v-html="footerText"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { useState } from "@/App.store";
import getCurrencies from "@/API/methods/getCurrencies";

import currencies from "@/config/currencies";

import UiSelectButton from "@/components/UI/SelectButton.vue";

export default {
  components: { UiSelectButton },
  props: {
    textKey: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const state = useState();

    return {
      state,
      currencies,
      texts: {
        perDay: state.app.locale.redirectPage.footerTextOne,
        income: state.app.locale.redirectPage.footerTextTwo,
      },
    };
  },
  computed: {
    footerText() {
      const routeName = this.$route.name.toLowerCase();

      if (routeName === "discounts") return this.texts.income;

      if (routeName === "products" && this.textKey === "friends") {
        return this.texts.income;
      } else {
        return this.texts.perDay;
      }
    },
  },
  methods: {
    selectCurrency() {
      this.$popup.open("options", {
        title: this.state.app.locale.redirectPage.currencySelectTitle,
        options: Object.values(this.currencies).map((currency) => ({
          ...currency,
          text: this.state.app.locale.redirectPage[
            `currencySelect_${currency.code}`
          ],
        })),
        description:
          this.state.app.locale.redirectPage.currencySelectDescription,
        callbackFunction: this.setCurrency,
      });
    },

    async setCurrency(currency) {
      try {
        let coefficient = 1;

        if (currency.abbr !== "KZT") {
          const data = await getCurrencies(currency.abbr);
          coefficient = data[currency.abbr];
        }

        this.state.changeCurrency(currency, coefficient);

        localStorage.setItem("currency", currency.code);
        localStorage.setItem("currencyCoefficient", String(coefficient));
      } catch (error) {
        console.log(error);
      } finally {
        this.$popup.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.layout-footer {
  background-color: $color-light-gray-1;

  &__container {
    padding: 24px 32px;
    margin: 0 auto;

    @media screen and (max-width: 480px) {
      max-width: 375px;
      padding: 32px 16px;
    }
  }

  &__button {
    width: 40px;
    height: 40px;
    font-size: 27px;
    margin-bottom: 16px;

    &--currencyKGS {
      font-size: 16px;
    }
  }

  &__text {
    color: $color-gray-40;
  }

  &__font {
    &--text {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.005em;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>
