import Fetch from "@/API/Fetch";

import kzDataAdapter from "@/utils/data-adapters/kzDataAdapter";
import mnDataAdapter from "@/utils/data-adapters/mnDataAdapter";

export default async (title) => {
  const promises = [
    Fetch("GET", "https://calculator.amwayservices.ru/api/v1/rates").then(
      (response) => kzDataAdapter(response)
    ),
  ];

  if (title === "MNT") {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    promises.push(
      fetch(
        `https://www.mongolbank.mn/en/currency-rate/data?startDate=${formattedDate}&endDate=${formattedDate}`,
        { method: "POST" }
      )
        .then((response) => response.json())
        .then((response) => mnDataAdapter(response.data[0]))
    );
  }

  const [kz, mnUSD] = await Promise.all(promises);

  const kzUSD = kz.find((currency) => currency.title === "USD");
  const kzKGS = kz.find((currency) => currency.title === "KGS");
  const kzRUB = kz.find((currency) => currency.title === "RUB");

  const currencies = {
    KGS: 1 / kzKGS.value,
    RUB: 1 / kzRUB.value,
  };

  if (title === "MNT") {
    currencies.MNT = Number((mnUSD.value / kzUSD.value).toFixed(2)) || null;
  }

  return currencies;
};
