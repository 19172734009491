<template>
  <a class="ui-button-with-icon" :href="href">
    <div class="ui-button-with-icon__icon ui-button-with-icon__icon--type">
      <slot name="iconType" />
    </div>

    <div class="ui-button-with-icon__text">
      <slot />
    </div>

    <div class="ui-button-with-icon__icon ui-button-with-icon__icon--arrow">
      <slot name="iconArrow" />
    </div>
  </a>
</template>

<script>
export default {
  name: "UiButtonWithIcon",
  props: {
    href: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style scoped lang="scss">
.ui-button-with-icon {
  padding: 11px 24px;
  background-color: $color-white;
  border: 1px solid $color-light-gray;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.15),
    1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  align-items: center;
  color: $color-dark;
  text-decoration: none;

  @media screen and (max-width: 480px) {
    padding: 8px 16px;
  }

  &__icon {
    &--type {
      margin-right: 20px;
      height: 66px;

      @media screen and (max-width: 480px) {
        margin-right: 16px;
      }
    }

    &--arrow {
      margin-left: auto;
    }
  }
}
</style>
