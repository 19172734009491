<template>
  <div class="bundles-section-navigation">
    <button
      type="button"
      class="bundles-section-navigation__button bundles-section-navigation__button--prev"
      @click="emitPrev"
    >
      <IconsArrowSm
        class="bundles-section-navigation__icon bundles-section-navigation__icon--prev"
      />

      <span
        class="bundles-section-navigation__font bundles-section-navigation__font--title"
      >
        {{ prevTitle }}
      </span>
    </button>

    <button
      type="button"
      class="bundles-section-navigation__button bundles-section-navigation__button--next"
      @click="emitNext"
    >
      <span
        class="bundles-section-navigation__font bundles-section-navigation__font--title"
      >
        {{ nextTitle }}
      </span>

      <IconsArrowSm
        class="bundles-section-navigation__icon bundles-section-navigation__icon--next"
      />
    </button>
  </div>
</template>

<script>
import IconsArrowSm from "@/components/icons/IconsArrowSm.vue";

export default {
  name: "BundlesSectionNavigation",
  components: { IconsArrowSm },
  props: {
    nextTitle: {
      type: String,
      default: "Далее",
    },
    prevTitle: {
      type: String,
      default: "Назад",
    },
  },
  emits: ["next", "prev"],
  methods: {
    emitNext() {
      this.$emit("next");
    },
    emitPrev() {
      this.$emit("prev");
    },
  },
};
</script>

<style scoped lang="scss">
.bundles-section-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button {
    display: flex;
    align-items: center;
    color: $color-gray;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__icon {
    width: 14px;
    height: 14px;
    color: $color-gray;

    &--prev {
      margin-right: 8px;
      transform: rotate(180deg);
    }

    &--next {
      margin-left: 8px;
    }
  }

  &__font {
    &--title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      @media screen and (max-width: 480px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
</style>
