export default [
  {
    code: "kz",
  },
  {
    code: "mn",
  },
  {
    code: "ky",
  },
  {
    code: "ru",
  },
];
