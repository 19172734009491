export default (app) => {
  const { $emitter } = app.config.globalProperties;

  app.config.globalProperties.$popup = {
    // open events
    onOpen(callback) {
      $emitter.on("openPopup", callback);
    },
    offOpen(callback) {
      $emitter.off("openPopup", callback);
    },

    // close events
    onClose(callback) {
      $emitter.on("closePopup", callback);
    },
    offClose(callback) {
      $emitter.off("closePopup", callback);
    },

    // close all events
    onCloseAll(callback) {
      $emitter.on("closeAllPopups", callback);
    },
    offCloseAll(callback) {
      $emitter.off("closeAllPopups", callback);
    },

    // methods
    open(name, data = {}, { label, closeCallback } = {}) {
      $emitter.emit("openPopup", { name, data, label, closeCallback });
    },
    close(label) {
      $emitter.emit("closePopup", label);
    },
    closeAll() {
      $emitter.emit("closeAllPopups");
    },
  };
};
