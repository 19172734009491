<template>
  <IconsSprite />

  <router-view> </router-view>

  <Popup class="app__popup" />
</template>

<script>
import { IconsSprite } from "@components/UI/Icon";
import Popup from "@/components/layout/Popup.vue";
import currencies from "@/config/currencies";
import languages from "@/config/languages";
import { useState } from "@/App.store";

export default {
  name: "App",
  components: {
    Popup,
    IconsSprite,
  },

  setup() {
    const state = useState();

    return {
      state,
      languages,
      currencies,
    };
  },
  mounted() {
    // Setting currency and locale
    const localeCode = localStorage.getItem("locale") || this.languages[3].code;
    const currency =
      this.currencies[localStorage.getItem("currency")] || this.currencies.kz;
    const currencyCoefficient =
      Number(localStorage.getItem("currencyCoefficient")) || 1;

    this.state.changeLocale(localeCode);
    localStorage.setItem("locale", localeCode);

    this.state.changeCurrency(currency, currencyCoefficient);
    localStorage.setItem("currency", currency.code);
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  .app__popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 300;
  }
}
</style>
