import { haveSponsorReward } from "@/utils/helpers/sponsor";
import rewardTypes from "@/constants/rewardTypes";

const getSum = (generators) => {
  return generators.reduce((sum, generator) => {
    if (generator.item.leader_value >= 10000 || generator.item.haveReward) {
      return sum + generator.item.leader_value;
    } else {
      if (generator.item.children.some((child) => !child.flag_guardianship)) {
        return sum + generator.item.leader_value;
      } else {
        return sum + 10000;
      }
    }
  }, 0);
};

const fullReward = (generators, bo) => {
  if (!generators.length) return;

  return getSum(generators) * bo * 0.04;
};

const partReward = (generators, bo) => {
  if (!generators.length) return;

  return getSum(generators) * bo * 0.04 - 10000 * bo * 0.04;
};

export default (tree, bo) => {
  const reward = haveSponsorReward(tree);

  switch (reward.type) {
    case rewardTypes.FULL:
      return fullReward(reward.generators, bo);

    case rewardTypes.PART:
      return partReward(reward.generators, bo);

    default:
      return 0;
  }
};
