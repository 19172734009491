export default {
  kz: {
    symbol: "₸",
    code: "kz",
    abbr: "KZT",
  },
  mn: {
    symbol: "₮",
    code: "mn",
    abbr: "MNT",
  },
  ky: {
    symbol: "KGS",
    code: "ky",
    abbr: "KGS",
  },
  ru: {
    symbol: "₽",
    code: "ru",
    abbr: "RUB",
  },
};
