<template>
  <section class="sections-products-friends">
    <BlocksProductsSummary
      class="sections-products-friends__summary"
      :products-count="productsCount"
      :total-price="formatCurrency(totalPrice)"
    />

    <div class="sections-products-friends__selectors">
      <BlocksFriendsSelector
        class="sections-products-friends__selector"
        :title="state.app.locale.redirectPage.client"
        :items="[1, 2, 3, 4, 5, 6]"
        :initial-items="children.firstLevel"
        @select="setChildrenAmount"
      />

      <BlocksFriendsSelector
        ref="childrenSecondLevel"
        class="sections-products-friends__selector"
        :title="state.app.locale.redirectPage.vipClient"
        :items="[7, 8, 9, 10, 11, 12]"
        :initial-items="children.secondLevel"
        @select="setChildrenChildrenAmount"
      />
    </div>

    <div class="sections-products-friends__blocks">
      <BlocksColoredInfo
        class="sections-products-friends__block sections-products-friends__block--profit"
        theme="green"
        with-button
        @component-click="openProfitPopup"
      >
        <div class="sections-products-friends__name">
          <span
            class="sections-products-friends__font sections-products-friends__font--name"
          >
            {{ state.app.locale.redirectPage.profit }}
          </span>
        </div>

        <div class="sections-products-friends__value">
          <span
            class="sections-products-friends__font sections-products-friends__font--value"
          >
            {{ formatCurrency(profitValue) }}
          </span>
        </div>
      </BlocksColoredInfo>

      <BlocksColoredInfo
        class="sections-products-friends__block sections-products-friends__block--points"
        theme="yellow"
      >
        <div class="sections-products-friends__info-wrapper">
          <div class="sections-products-friends__info">
            <div
              class="sections-products-friends__name sections-products-friends__name--points"
            >
              <span
                class="sections-products-friends__font sections-products-friends__font--name"
              >
                {{ state.app.locale.redirectPage.personalPoints }}
              </span>
            </div>

            <div class="sections-products-friends__value">
              <span
                class="sections-products-friends__font sections-products-friends__font--value"
              >
                {{ personalPoints }}
              </span>
            </div>
          </div>

          <div class="sections-products-friends__info">
            <div
              class="sections-products-friends__name sections-products-friends__name--points"
            >
              <span
                class="sections-products-friends__font sections-products-friends__font--name"
              >
                {{ state.app.locale.redirectPage.groupPoints }}
              </span>
            </div>

            <div class="sections-products-friends__value">
              <span
                class="sections-products-friends__font sections-products-friends__font--value"
              >
                {{ groupPoints }}
              </span>
            </div>
          </div>
        </div>
      </BlocksColoredInfo>

      <BlocksColoredInfo
        class="sections-products-friends__block sections-products-friends__block--potential"
        with-button
        @component-click="openIncomePopup"
      >
        <div class="sections-products-friends__name">
          <span
            class="sections-products-friends__font sections-products-friends__font--name"
          >
            {{ state.app.locale.redirectPage.potentialIncome }}
          </span>
        </div>

        <div class="sections-products-friends__value">
          <span
            class="sections-products-friends__font sections-products-friends__font--value"
          >
            {{ formatCurrency(incomeValue) }}
          </span>
        </div>
      </BlocksColoredInfo>
    </div>
  </section>
</template>

<script>
import BlocksProductsSummary from "@/components/blocks/BlocksProductsSummary.vue";
import BlocksFriendsSelector from "@/components/blocks/BlocksFriendsSelector.vue";
import BlocksColoredInfo from "@/components/blocks/BlocksColoredInfo.vue";
import { useState } from "@/App.store";
import formatCurrency from "../../../utils/helpers/formatCurrency";

export default {
  name: "SectionsProductsFriends",
  emits: ["selectChildren", "selectChildrenChildren"],
  components: {
    BlocksColoredInfo,
    BlocksFriendsSelector,
    BlocksProductsSummary,
  },
  props: {
    personalPoints: {
      type: Number,
      default: 0,
    },
    groupPoints: {
      type: Number,
      default: 0,
    },
    productsCount: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    profitValue: {
      type: Number,
      default: 0,
    },
    incomeValue: {
      type: Number,
      default: 0,
    },
    categoriesAbo: {
      type: Array,
      default: () => [],
    },
    categoriesVip: {
      type: Array,
      default: () => [],
    },
    children: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const state = useState();

    return {
      state,
    };
  },
  computed: {
    profitData() {
      return {
        title: this.state.app.locale.redirectPage.profit,
        text: this.state.app.locale.redirectPage.profitText,
        categories: this.categoriesVip,
        total: {
          name: this.state.app.locale.redirectPage.monthDiscount,
          value: this.profitValue,
        },
        theme: "green",
      };
    },
    incomeData() {
      return {
        title: this.state.app.locale.redirectPage.potentialIncome,
        text: this.state.app.locale.redirectPage.incomeText,
        categories: this.categoriesAbo,
        total: {
          name: this.state.app.locale.redirectPage.monthPayments,
          value: this.incomeValue,
        },
      };
    },
  },
  methods: {
    formatCurrency,
    openProfitPopup() {
      this.$popup.open("info", this.profitData);
    },
    openIncomePopup() {
      this.$popup.open("info", this.incomeData);
    },

    setChildrenAmount(items) {
      this.$emit("selectChildren", items);
    },
    setChildrenChildrenAmount(items) {
      this.$emit("selectChildrenChildren", items);
    },
  },
};
</script>

<style scoped lang="scss">
.sections-products-friends {
  $parent: &;

  &__summary {
    max-width: 100%;
    width: 464px;
    margin: 0 auto 32px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  &__selectors {
    margin-bottom: 40px;
  }

  &__selector {
    &:first-child {
      margin-bottom: 16px;
    }
  }

  &__blocks {
    width: 100%;
    max-width: 343px;
    margin: 0 auto 72px;
  }

  &__block {
    padding: 16px 24px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &--points {
      padding: 8px;
      cursor: default;

      #{$parent} {
        &__font {
          &--name,
          &--value {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  &__info-wrapper {
    display: flex;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  &__name {
    margin-bottom: 8px;

    &--points {
      margin: 0;
    }
  }

  &__font {
    display: block;

    &--name {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 18px;
    }

    &--value {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
    }
  }
}
</style>
