<template>
  <div class="layout-default">
    <div
      class="layout-default__container"
      :class="{
        'layout-default__container--background': withBg,
        'layout-default__container--contained': !$route.meta.disableContainer,
      }"
    >
      <Header class="layout-default__header" />

      <slot />

      <Footer
        v-if="!$route.meta.hideFooter"
        class="layout-default__footer"
        :text-key="footerText"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default {
  name: "LayoutDefault",
  components: { Footer, Header },
  props: {
    withBg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footerText: "products",
    };
  },
  methods: {
    setFooterText(key) {
      this.footerText = key;
    },
  },
};
</script>

<style scoped lang="scss">
.layout-default {
  background-color: #7fbc96;

  &__container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    background-color: $color-white;

    &--contained {
      max-width: 768px;
      margin: 0 auto;
    }

    &--background {
      background: url("/images/backgrounds/redirect-bg-tablet.png") no-repeat;
      background-size: cover;

      @media (max-width: 480px) {
        background: url("/images/backgrounds/redirect-bg.png") no-repeat;
        background-size: cover;
      }
    }
  }

  &__header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 52px;
  }

  &__footer {
    margin-top: auto;
  }
}
</style>
