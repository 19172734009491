<template>
  <div
    class="cards-product-discount"
    :class="{ 'cards-product-discount--active': isActive }"
    @click="emitClick"
  >
    <img
      class="cards-product-discount__check"
      src="/images/icons/check.svg"
      alt="check"
    />

    <img class="cards-product-discount__image" :src="imageSrc" alt="image" />

    <div class="cards-product-discount__info">
      <div class="cards-product-discount__name">
        <span
          class="cards-product-discount__font cards-product-discount__font--name"
        >
          {{ name }}
        </span>
      </div>

      <div class="cards-product-discount__values">
        <div class="cards-product-discount__price">
          <span
            class="cards-product-discount__font cards-product-discount__font--price"
          >
            {{ formatCurrency(price) }}
          </span>
        </div>

        <div class="cards-product-discount__points">
          <span
            class="cards-product-discount__font cards-product-discount__font--points"
          >
            {{ points }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatCurrency from "../../utils/helpers/formatCurrency";

export default {
  name: "CardsProductDiscount",
  emits: ["click"],
  props: {
    imageSrc: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    price: {
      type: Number,
      default: 0,
    },
    points: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCurrency,
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.cards-product-discount {
  $parent: &;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: $color-white;
  border: 1px solid $color-light-gray;
  border-radius: 12px;
  cursor: pointer;

  &__check {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 32px;
    height: 32px;
    display: none;
  }

  &__image {
    width: 64px;
    margin-right: 8px;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: $color-dark;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__values {
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  &__price {
    position: relative;
    color: $color-blue;
    margin-right: 16px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -8px;
      width: 1px;
      height: 24px;
      background-color: $color-mid-gray;
    }
  }

  &__points {
    color: $color-gray-1;
  }

  &__font {
    &--name {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 14px;
      line-height: 16px;
    }

    &--price {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    &--points {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--active {
    border-color: $color-light-green-1;
    border-width: 2px;
    padding: 7px;

    #{$parent} {
      &__check {
        display: block;
      }
    }
  }
}
</style>
