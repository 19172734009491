import { useState } from "@/App.store";

export default (value) => {
  const { app } = useState();

  if (!value) return `${app.currency.symbol} 0`;

  const formattedValue = Math.floor(value * app.currencyCoefficient);

  return `${app.currency.symbol} ${Number(formattedValue).toLocaleString()}`;
};
