<template>
  <LayoutDefault class="pages-redirect" with-bg>
    <div class="pages-redirect__container">
      <div class="pages-redirect__options">
        <UiSelectButton
          class="pages-redirect__button pages-redirect__button--option pages-redirect__button--option--currency"
          :class="{
            'pages-redirect__button--option--currencyKGS':
              state.app.currency.symbol === 'KGS',
          }"
          @click="selectCurrency"
        >
          {{ state.app.currency.symbol }}
        </UiSelectButton>

        <UiSelectButton
          class="pages-redirect__button pages-redirect__button--option"
          @click="selectLanguage"
        >
          {{ state.app.locale._code }}
        </UiSelectButton>
      </div>

      <div class="pages-redirect__routes">
        <UiButtonWithIcon
          v-for="route in routes"
          :key="route.name"
          :href="route.link"
          class="pages-redirect__button pages-redirect__button--route"
        >
          <template #iconType>
            <img
              class="pages-redirect__icon pages-redirect__icon--type"
              :src="`/images/icons/types/${route.iconName}.svg`"
              alt="type"
            />
          </template>

          <template #default>
            <span class="pages-redirect__font pages-redirect__font--name">
              {{ route.name }}
            </span>
          </template>

          <template #iconArrow>
            <img
              class="pages-redirect__icon pages-redirect__icon--arrow"
              src="/images/icons/arrow-right.svg"
              alt="arrow"
            />
          </template>
        </UiButtonWithIcon>
      </div>
        <div class="pages-redirect__description">
          <a :href="state.app.locale.redirectPage.how_works_link" target="_blank" class="pages-redirect__description_link">
            <svg width="21" style="margin-right: 8px" height="20" viewBox="0 0 21 20" fill="#2C2C2C" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333C6.3181 3.33333 3.33333 6.3181 3.33333 10C3.33333 13.6819 6.3181 16.6667 10 16.6667ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="#2C2C2C"/>
                <path d="M13.9643 9.35405L8.46454 6.10262C8.01768 5.83857 7.33333 6.09481 7.33333 6.74791V13.2492C7.33333 13.8351 7.96924 14.1882 8.46454 13.8945L13.9643 10.6446C14.4549 10.3556 14.4565 9.6431 13.9643 9.35405Z" fill="#2C2C2C"/>
            </svg>

            <span
                class="pages-redirect__font pages-redirect__font--description"
                v-html="state.app.locale.redirectPage.how_works"
        />
          </a>
        </div>
      <div class="pages-redirect__description">
        <span
          class="pages-redirect__font pages-redirect__font--description"
          v-html="state.app.locale.redirectPage.description"
        />
      </div>
    </div>
  </LayoutDefault>
</template>

<script>
import { useState } from "@/App.store";
import getCurrencies from "@/API/methods/getCurrencies";

import routes from "@/constants/routes";
import languages from "@/config/languages";
import currencies from "@/config/currencies";

import LayoutDefault from "@/layout/LayoutDefault.vue";
import UiSelectButton from "@/components/UI/SelectButton.vue";
import UiButtonWithIcon from "@/components/UI/ButtonWithIcon.vue";

export default {
  name: "RedirectPage",
  components: { UiButtonWithIcon, UiSelectButton, LayoutDefault },
  setup() {
    const state = useState();

    return {
      state,
      languages,
      currencies,
    };
  },
  computed: {
    routes() {
      return [
        {
          name: this.state.app.locale.redirectPage.routes.product,
          iconName: "products",
          link: routes.PRODUCTS,
        },
        {
          name: this.state.app.locale.redirectPage.routes.discount,
          iconName: "discount",
          link: routes.DISCOUNTS,
        },
        {
          name: this.state.app.locale.redirectPage.routes.income,
          iconName: "income",
          link: routes.INCOME,
        },
      ];
    },
  },
  methods: {
    selectCurrency() {
      this.$popup.open("options", {
        title: this.state.app.locale.redirectPage.currencySelectTitle,
        options: Object.values(this.currencies).map((currency) => ({
          ...currency,
          text: this.state.app.locale.redirectPage[
            `currencySelect_${currency.code}`
          ],
        })),
        description:
          this.state.app.locale.redirectPage.currencySelectDescription,
        callbackFunction: this.setCurrency,
      });
    },
    selectLanguage() {
      this.$popup.open("options", {
        title: this.state.app.locale.redirectPage.langSelectTitle,
        options: this.languages.map((language) => ({
          ...language,
          text: this.state.app.locale.redirectPage[
            `langSelect_${language.code}`
          ],
        })),
        callbackFunction: this.setLocale,
      });
    },
    async setCurrency(currency) {
      try {
        let coefficient = 1;

        if (currency.abbr !== "KZT") {
          const data = await getCurrencies(currency.abbr);
          coefficient = data[currency.abbr];
        }

        this.state.changeCurrency(currency, coefficient);

        localStorage.setItem("currency", currency.code);
        localStorage.setItem("currencyCoefficient", String(coefficient));
      } catch (error) {
        console.log(error);
      } finally {
        this.$popup.close();
      }
    },
    setLocale(option) {
      this.state.changeLocale(option.code);
      localStorage.setItem("locale", option.code);

      this.$popup.close();
    },
  },
};
</script>

<style scoped lang="scss">
.pages-redirect {
  &__container {
    display: flex;
    flex-direction: column;
    max-width: 348px;
    width: 100%;
    margin: 0 auto;
    padding-top: 116px;
    padding-bottom: 50px;

    @media screen and (max-width: 480px) {
      padding: 74px 32px 50px;
      max-width: 375px;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    margin: 0 auto 65px;

    @media screen and (max-width: 480px) {
      margin: 0 auto 36px;
    }
  }

  &__routes {
    margin-bottom: 48px;

    @media screen and (max-width: 480px) {
      margin-bottom: 32px;
    }
  }

  &__description {
    text-align: center;
    color: $color-dark;
    padding-bottom: 16px;
  }
  &__description_link{
    display: flex;
    align-items: center;
    justify-content: center;
    color:#2C2C2C;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: GT Walsheim Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 18px; /* 128.571% */
    text-decoration-line: underline;
    &:hover{
      text-decoration-line: none;
    }
  }
  &__button {
    &--option {
      width: 40px;
      height: 40px;
      font-size: 18px;
      text-transform: uppercase;

      &:first-child {
        margin-right: 24px;
      }

      &--currency {
        font-size: 27px;
      }
      &--currencyKGS {
        font-size: 16px;
      }
    }

    &--route {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__font {
    &--name {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 22px;
      line-height: 21px;
      font-weight: 700;

      @media screen and (max-width: 480px) {
        font-size: 18px;
      }
    }

    &--description {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
