import { createRouter, createWebHistory } from "vue-router";

import routesItems from "@/constants/routes";

import Main from "@/pages/Main";
import Redirect from "@/pages/redirect.vue";
import Products from "@/pages/products.vue";
import Discounts from "@/pages/discounts.vue";

const routes = [
  {
    path: "",
    name: "Redirect",
    component: Redirect,
    meta: {
      hideFooter: true,
    },
  },
  {
    path: routesItems.PRODUCTS,
    name: "Products",
    component: Products,
  },
  {
    path: routesItems.DISCOUNTS,
    name: "Discounts",
    component: Discounts,
  },
  {
    path: routesItems.INCOME,
    name: "Main",
    component: Main,
    meta: {
      disableContainer: true,
      hideFooter: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
