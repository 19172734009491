/* eslint-disable */

import Breakpoints from "breakpoints-js";
import { reactive, inject, readonly } from "vue";
import locales from "@src/locales";
import currencies from "@/config/currencies";
import { defaultTree, calcTree as CalcTree } from "@src/utils/Calc.js";

import { GetUser, Load, Save, DownloadPDF } from "@src/API";
import transform from "@/utils/Format";
import { v4 as uuid } from "uuid";

Breakpoints({
  mobile: {
    min: 0,
    max: 570,
  },
  tablet: {
    min: 571,
    max: 991,
  },
  desktop: {
    min: 998,
    max: Infinity,
  },
});

export const stateSymbol = Symbol("app");

export const createState = (_state = { locale: "ru" }) => {
  let currentBreakpoint = Breakpoints.current();
  let state = reactive({
    locale: locales[_state.locale],
    currency: currencies.kz,
    currencyCoefficient: 1,
    calculator: {
      country: null,
      clientType: null,
      total: 0,
      date: null,
    },
    breakpoint: {
      mobile: currentBreakpoint?.name == "mobile",
      tablet: currentBreakpoint?.name == "tablet",
      desktop: currentBreakpoint?.name == "desktop",
    },
    Tree: defaultTree(),
    tutorialStep: null,
    // window.localStorage.getItem("tutorialMode") == "null" ? null : "country",
    currentPopup: null,
    saved: false,
  });

  GetUser().then((user) => (state.user = user));

  const changeLocale = (code) => {
    state.locale = locales[code];
    state.saved = false;
  };
  const changeCountry = (country) => {
    state.calculator.country = country;
    state.saved = false;
  };
  const changeCurrency = (currency, coefficient) => {
    state.currency = currency;
    state.currencyCoefficient = coefficient;
    state.saved = false;
  };

  const changeClientType = (clientType) => {
    state.calculator.clientType = clientType;

    // add 100 points by default for VIP client type
    if (state.Tree.personal_value === 0) {
      state.Tree.personal_value = clientType === "VIP" ? 100 : 0;

      state.saved = false;
    }
  };

  const changeTutorialStep = (step) => {
    if (state.tutorialStep) state.tutorialStep = step;
  };

  const startTutorial = () => {
    window.localStorage.removeItem("tutorialMode");
    window.location.reload();
  };

  const endTutorial = () => {
    window.localStorage.setItem("tutorialMode", "null");
    state.tutorialStep = null;
  };

  const setcurrentPopup = (modal) => (state.currentPopup = modal);

  const calcTree = (
    tree = state.Tree,
    clientType = state.calculator.clientType
  ) => {
    CalcTree(tree, {
      BO: state.calculator.country,
      business_nature: clientType,
    });

    const calculateTotalByPlan = (planObject) => {
      Object.values(planObject).forEach(
        (value) =>
          (state.calculator.total = Math.floor(state.calculator.total + value))
      );
    };
    tree.businessNature = state.calculator.clientType;

    state.saved = false;

    state.calculator.total = 0;

    calculateTotalByPlan(tree.amway_plan);
    calculateTotalByPlan(tree.business_plus);

    if (tree.personal_sells_amount) {
      state.calculator.total += tree.personal_sells_amount;
    }

    if (tree.personal_friends_buy) {
      state.calculator.total += tree.personal_friends_buy;
    }

    if (state.calculator.clientType === "VIP") {
      tree.flag_bronze_start = false;
      tree.flag_bronze_bonus = false;
      tree.hideBronze = true;
    }

    return tree;
  };

  const saveTree = async () => {
    let isNew = !Boolean(state.calculator.date);

    let [tree, meta] = await Save(
      state.Tree,
      {
        country: state.calculator.country,
        clientType: state.calculator.clientType,
        total: state.calculator.total,
        // date: state.calculator.date,
      },
      isNew
    );

    // if(!state.calculator.date) state.calculator.date;
    state.calculator = meta;
    Object.assign(state.Tree, tree);

    state.saved = true;

    let user = await GetUser();
    state.user = user;
    return [tree, meta];
  };

  const loadTree = async (id) => {
    let [tree, meta] = await Load(id);

    meta.total = meta.total.toLocaleString("ru-RU");

    state.saved = true;

    state.calculator = meta;

    // switch(meta.country){
    //   case 88:
    //     state.locale = locales['ru']
    //   break;
    //   case 517:
    //     state.locale = locales['kz']
    //   break;
    // }

    Object.assign(state.Tree, tree);
    calcTree();
  };

  const generateTree = ({
    children: { firstLevel = 1, secondLevel = 1 },
    points = 100,
    clientType = "ABO",
    generateType = "product",
  }) => {
    // Create new tree
    changeClientType(null);

    const tree = { ...defaultTree(), personal_value: points };

    if (generateType === "product") {
      // Set children
      if (secondLevel > 0) {
        for (let index = 0; index < secondLevel; index++) {
          tree.children.push({
            ...defaultTree(),
            root: false,
            personal_value: points + firstLevel * points,
          });
        }
      }

      if (firstLevel > 0) {
        tree.client_value = firstLevel * points;
      }
    } else {
      if (firstLevel >= 1 && secondLevel >= 0) {
        for (let index = 0; index < firstLevel; index++) {
          tree.children.push({
            ...defaultTree(),
            root: false,
            personal_value: points,
          });
        }

        // Set children's children
        tree.children.forEach((child) => {
          for (let innerIndex = 0; innerIndex < secondLevel; innerIndex++) {
            child.children.push({
              ...defaultTree(),
              root: false,
              personal_value: points,
            });
          }
        });
      }
    }

    if (clientType === "VIP") {
      tree.flag_bronze_start = false;
    }

    Object.assign(state.Tree, tree);

    changeClientType(clientType);

    // Todo add dynamics
    changeCountry(710);

    const calculatedTree = calcTree(tree, clientType);

    return {
      tree: { ...calculatedTree },
    };
  };

  const getTreeParams = (tree) => {
    const params = {};

    params.total = Object.values(tree.amway_plan).reduce(
      (sum, value) => sum + Math.floor(value),
      0
    );

    const businessPlus = Object.values(tree.business_plus).reduce(
      (sum, value) => sum + Math.floor(value),
      0
    );
    params.total += businessPlus;

    params.total += Math.floor(tree.personal_friends_buy) || 0;
    params.total += Math.floor(tree.personal_sells_amount) || 0;

    params.personalPoints = tree.personal_points;
    params.groupPoints = tree.group_points;

    params.details = {
      personalActivity: {
        text: "forPersonalActivity",
        tooltip: "tooltipPersonalActivity",
        value: tree.amway_plan.personal_achievements,
      },
      groupActivity: {
        text: "forGroupActivity",
        tooltip: "tooltipGroupActivity",
        value: tree.amway_plan.personal_percent_difference,
      },
      stableBusiness: {
        text: "forStableBusiness",
        tooltip: "tooltipStableBusiness",
        value: businessPlus,
      },
      leadership: {
        text: "forGuardianship",
        tooltip: "tooltipGuardianship",
        value: 0,
      },
    };

    params.details.personalActivity.value += tree.personal_friends_buy || 0;
    params.details.personalActivity.value += tree.personal_sells_amount || 0;

    params.details.leadership.value += tree.amway_plan.plan_leader_reward || 0;
    params.details.leadership.value += tree.amway_plan.ruby_reward || 0;
    params.details.leadership.value += tree.amway_plan.pearl_reward || 0;

    return params;
  };

  const downloadPDF = async () => {
    if (!state.saved) await saveTree();

    DownloadPDF(state.Tree.id, state.Tree.name);
  };

  const Reset = async (id) => {
    // window.location.reload();
    state.currentPopup.openSwitch(false);
    document.querySelector(".popup-container").classList.toggle("show", false);
    state.locale = locales[_state.locale];

    Object.assign(state.Tree, defaultTree());

    state.saved = false;

    state.calculator = {
      country: null,
      clientType: null,
      total: 0,
      date: null,
    };
  };

  Breakpoints.on("mobile tablet desktop", "enter", (...a) => {
    currentBreakpoint = Breakpoints.current();

    state.breakpoint.mobile = currentBreakpoint.name == "mobile";
    state.breakpoint.tablet = currentBreakpoint.name == "tablet";
    state.breakpoint.desktop = currentBreakpoint.name == "desktop";
  });

  return {
    changeLocale,
    changeCountry,
    changeCurrency,
    changeClientType,

    changeTutorialStep,
    startTutorial,
    endTutorial,

    setcurrentPopup,
    calcTree,

    saveTree,
    loadTree,
    generateTree,
    getTreeParams,
    downloadPDF,

    Reset,

    app: readonly(state),
    Tree: state.Tree,
    // breakpoint
  };
};

export const useState = () => inject(stateSymbol);
