<template>
  <section class="sections-discounts">
    <div class="swiper-container sections-discounts__slider">
      <div class="swiper-wrapper">
        <CardsProductDiscount
          v-for="product in products"
          class="sections-discounts__card swiper-slide"
          :key="product.article"
          :image-src="product.image"
          :points="product.score"
          :name="product.name[state.app.locale._code]"
          :price="product.price"
          :is-active="isSelected(product)"
          @click="setSelect(product)"
        />
      </div>

      <SliderNavigationButton
        v-if="!swiperBeginning"
        ref="navigationPrev"
        class="sections-discounts__button sections-discounts__button--prev"
        direction="left"
        @click="slidePrev"
      />
      <SliderNavigationButton
        v-if="!swiperEnd"
        ref="navigationNext"
        class="sections-discounts__button sections-discounts__button--next"
        @click="slideNext"
      />
    </div>

    <BlocksProductsSummary
      class="sections-discounts__summary"
      :products-count="productsCount"
      :total-price="formatCurrency(totalPrice)"
    />

    <div class="sections-discounts__selectors">
      <BlocksFriendsSelector
        class="sections-discounts__selector"
        :title="state.app.locale.redirectPage.friends"
        :items="[1, 2, 3, 4, 5, 6]"
        @select="setChildrenAmount"
      />

      <BlocksFriendsSelector
        ref="childrenSecondLevel"
        class="sections-discounts__selector"
        :title="state.app.locale.redirectPage.friendsFriend"
        :items="[7, 8, 9, 10, 11, 12]"
        :clickable="children.firstLevel"
        @select="setChildrenChildrenAmount"
      />
    </div>

    <div class="sections-discounts__blocks">
      <BlocksColoredInfo
        class="sections-discounts__block sections-discounts__block--profit"
        theme="green"
        with-button
        @component-click="openProfitPopup"
      >
        <div class="sections-discounts__name">
          <span class="sections-discounts__font sections-discounts__font--name">
            {{ state.app.locale.redirectPage.profit }}
          </span>
        </div>

        <div class="sections-discounts__value">
          <span
            class="sections-discounts__font sections-discounts__font--value"
          >
            {{ formatCurrency(profitValue) }}
          </span>
        </div>
      </BlocksColoredInfo>

      <BlocksColoredInfo
        class="sections-discounts__block sections-discounts__block--potential"
        with-button
        @component-click="openIncomePopup"
      >
        <div class="sections-discounts__name">
          <span class="sections-discounts__font sections-discounts__font--name">
            {{ state.app.locale.redirectPage.potentialIncome }}
          </span>
        </div>

        <div class="sections-discounts__value">
          <span
            class="sections-discounts__font sections-discounts__font--value"
          >
            {{ formatCurrency(incomeValue) }}
          </span>
        </div>
      </BlocksColoredInfo>

      <BlocksColoredInfo
        class="sections-discounts__block sections-discounts__block--points"
        theme="yellow"
      >
        <div class="sections-discounts__info-wrapper">
          <div class="sections-discounts__info">
            <div
              class="sections-discounts__name sections-discounts__name--points"
            >
              <span
                class="sections-discounts__font sections-discounts__font--name"
              >
                {{ state.app.locale.redirectPage.personalPoints }}
              </span>
            </div>

            <div class="sections-discounts__value">
              <span
                class="sections-discounts__font sections-discounts__font--value"
              >
                {{ totalPersonalPoints }}
              </span>
            </div>
          </div>

          <div class="sections-discounts__info">
            <div
              class="sections-discounts__name sections-discounts__name--points"
            >
              <span
                class="sections-discounts__font sections-discounts__font--name"
              >
                {{ state.app.locale.redirectPage.groupPoints }}
              </span>
            </div>

            <div class="sections-discounts__value">
              <span
                class="sections-discounts__font sections-discounts__font--value"
              >
                {{ totalGroupPoints }}
              </span>
            </div>
          </div>
        </div>
      </BlocksColoredInfo>
    </div>
  </section>
</template>

<script>
import products from "@/config/products";

import { useState } from "@/App.store";
import BlocksColoredInfo from "@/components/blocks/BlocksColoredInfo.vue";
import BlocksFriendsSelector from "@/components/blocks/BlocksFriendsSelector.vue";
import BlocksProductsSummary from "@/components/blocks/BlocksProductsSummary.vue";
import CardsProductDiscount from "@/components/cards/CardsProductDiscount.vue";
import Swiper from "swiper";
import "swiper/swiper.scss";
import formatCurrency from "@/utils/helpers/formatCurrency";
import SliderNavigationButton from "@/components/UI/SliderNavigationButton.vue";

export default {
  name: "SectionsDiscounts",
  components: {
    SliderNavigationButton,
    CardsProductDiscount,
    BlocksProductsSummary,
    BlocksColoredInfo,
    BlocksFriendsSelector,
  },
  emits: ["selectProduct", "selectChildren", "selectChildrenChildren"],
  props: {
    totalPersonalPoints: {
      type: Number,
      default: 0,
    },
    totalGroupPoints: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    productsCount: {
      type: Number,
      default: 0,
    },
    profitValue: {
      type: Number,
      default: 0,
    },
    categoriesAbo: {
      type: Array,
      default: () => [],
    },
    categoriesVip: {
      type: Array,
      default: () => [],
    },
    incomeValue: {
      type: Number,
      default: 0,
    },
    children: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const state = useState();

    return {
      state,
      products,
    };
  },
  data() {
    return {
      swiper: undefined,
      swiperBeginning: true,
      swiperEnd: false,
      selectedProducts: [],
    };
  },
  computed: {
    profitData() {
      return {
        title: this.state.app.locale.redirectPage.profit,
        text: this.state.app.locale.redirectPage.profitText,
        categories: this.categoriesVip,
        total: {
          name: this.state.app.locale.redirectPage.monthDiscount,
          value: this.profitValue,
        },
        theme: "green",
      };
    },
    incomeData() {
      return {
        title: this.state.app.locale.redirectPage.potentialIncome,
        text: this.state.app.locale.redirectPage.incomeText,
        categories: this.categoriesAbo,
        total: {
          name: this.state.app.locale.redirectPage.monthPayments,
          value: this.incomeValue,
        },
      };
    },
  },
  mounted() {
    this.initializeSwiper();
  },
  watch: {
    swiper: {
      handler(swiper) {
        this.swiperBeginning = swiper.isBeginning;
        this.swiperEnd = swiper.isEnd;
      },
      deep: true,
    },
  },
  methods: {
    formatCurrency,

    initializeSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: "auto",
        spaceBetween: 8,
        slideToClickedSlide: true,
        observer: true,
        on: {
          slideChange: (swiper) => {
            this.swiperBeginning = swiper.isBeginning;
            this.swiperEnd = swiper.isEnd;
          },
        },
      });
    },
    slideNext() {
      this.swiper.slideNext();
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    setSelect(product) {
      const index = this.selectedProducts.findIndex(
        (item) => item.article === product.article
      );

      if (index === -1) {
        this.selectedProducts.push(product);
      } else {
        this.selectedProducts.splice(index, 1);
      }
      this.$emit("selectProduct", this.selectedProducts);
    },
    isSelected(product) {
      return !!this.selectedProducts.find(
        (item) => item.article === product.article
      );
    },

    openProfitPopup() {
      this.$popup.open("info", this.profitData);
    },
    openIncomePopup() {
      this.$popup.open("info", this.incomeData);
    },

    setChildrenAmount(items) {
      this.$emit("selectChildren", items);
    },
    setChildrenChildrenAmount(items) {
      this.$emit("selectChildrenChildren", items);
    },
  },
};
</script>

<style scoped lang="scss">
.sections-discounts {
  $parent: &;

  &__slider {
    position: relative;
    margin-bottom: 24px;
    overflow: visible;

    @media screen and (min-width: 480px) {
      padding: 0 24px;
    }
  }

  &__button {
    position: absolute;
    z-index: 2;
    top: 50%;
    height: calc(100% + 2px);

    &--prev {
      transform: translateY(50%);
      left: -36px;
    }

    &--next {
      transform: translateY(-50%);
      right: -36px;
    }
  }

  &__card {
    width: 253px;
    height: auto;
  }

  &__summary {
    width: 100%;
    max-width: 343px;
    margin: 0 auto 32px;
  }

  &__selectors {
    width: 100%;
    max-width: 552px;
    margin: 0 auto 40px;
  }

  &__selector {
    &:first-child {
      margin-bottom: 16px;
    }
  }

  &__blocks {
    width: 100%;
    max-width: 343px;
    margin: 0 auto 22px;

    @media screen and (max-width: 480px) {
      margin-bottom: 72px;
    }
  }

  &__block {
    padding: 16px 24px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &--points {
      padding: 8px;
      cursor: default;

      #{$parent} {
        &__font {
          &--name,
          &--value {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  &__info-wrapper {
    display: flex;
    width: 100%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  &__name {
    margin-bottom: 8px;

    &--points {
      margin: 0;
    }
  }

  &__font {
    display: block;

    &--name {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 16px;
      line-height: 18px;
    }

    &--value {
      font-family: GT Walsheim Pro, sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 32px;
    }
  }
}
</style>
