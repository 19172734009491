<template>
  <LayoutDefault class="pages-discounts">
    <div class="pages-discounts__container">
      <SectionsDiscounts
        ref="section"
        :total-price="totalPrice"
        :products-count="productsCount"
        :total-personal-points="totalPersonalPoints"
        :total-group-points="totalGroupPoints"
        :profit-value="this.trees['VIP'].total"
        :income-value="this.trees['ABO'].total"
        :categories-abo="categoriesAbo"
        :categories-vip="categoriesVip"
        :children="children"
        @selectProduct="onProductsSelect"
        @selectChildren="(items) => setChildren(items, 'firstLevel')"
        @selectChildrenChildren="(items) => setChildren(items, 'secondLevel')"
      />

      <BundlesSectionNavigation
        class="pages-products__navigation"
        :next-title="state.app.locale.redirectPage.navigation.next"
        :prev-title="state.app.locale.redirectPage.navigation.prev"
        @next="nextSection"
        @prev="prevSection"
      />
    </div>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from "@/layout/LayoutDefault.vue";
import SectionsDiscounts from "@/components/sections/SectionsDiscounts.vue";
import BundlesSectionNavigation from "@/components/bundles/SectionNavigation.vue";
import { useState } from "@/App.store";

export default {
  name: "DiscountsPage",
  components: { BundlesSectionNavigation, LayoutDefault, SectionsDiscounts },
  setup() {
    const state = useState();

    return {
      state,
    };
  },
  data() {
    return {
      products: [],
      trees: {},
      children: {
        firstLevel: 0,
        secondLevel: 0,
      },
    };
  },
  watch: {
    children: {
      handler() {
        this.setTrees();
      },
      deep: true,
      immediate: true,
    },
    products: {
      handler() {
        this.setTrees();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    productsCount() {
      return this.products.length;
    },
    totalPrice() {
      return this.products.reduce((sum, item) => sum + item.price, 0);
    },
    totalPersonalPoints() {
      return this.products
        .reduce((sum, item) => sum + item.score, 0)
        .toFixed(2);
    },
    totalGroupPoints() {
      return (
        (1 +
          this.children.firstLevel +
          this.children.firstLevel * this.children.secondLevel) *
        this.totalPersonalPoints
      ).toFixed(2);
    },

    categoriesAbo() {
      const categories = [
        {
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.personalActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.amway[
              this.trees["ABO"].details.personalActivity.tooltip
            ],
          value: this.trees["ABO"].details.personalActivity.value,
        },
        {
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.groupActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.amway[
              this.trees["ABO"].details.groupActivity.tooltip
            ],
          value: this.trees["ABO"].details.groupActivity.value,
        },
        {
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.stableBusiness.text
          ],
          tooltip:
            this.state.app.locale.redirectPage[
              this.trees["ABO"].details.stableBusiness.tooltip
            ],
          value: this.trees["ABO"].details.stableBusiness.value,
        },
      ];

      if (this.trees["ABO"].details.leadership.value) {
        categories.push({
          name: this.state.app.locale.redirectPage[
            this.trees["ABO"].details.leadership.text
          ],
          tooltip:
            this.state.app.locale.redirectPage[
              this.trees["ABO"].details.leadership.tooltip
            ],
          value: this.trees["ABO"].details.leadership.value,
        });
      }

      return categories;
    },
    categoriesVip() {
      return [
        {
          name: this.state.app.locale.redirectPage[
            this.trees["VIP"].details.personalActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.loyalty[
              this.trees["VIP"].details.personalActivity.tooltip
            ],
          value: this.trees["VIP"].details.personalActivity.value,
        },
        {
          name: this.state.app.locale.redirectPage[
            this.trees["VIP"].details.groupActivity.text
          ],
          tooltip:
            this.state.app.locale.redirectPage.loyalty[
              this.trees["VIP"].details.groupActivity.tooltip
            ],
          value: this.trees["VIP"].details.groupActivity.value,
        },
      ];
    },
  },
  methods: {
    prevSection() {
      this.$router.push({ path: "/" });
    },
    nextSection() {
      this.finishTree();
    },

    onProductsSelect(products) {
      this.products = [...products];
    },
    setChildren(items, level) {
      this.children[level] = items.length;

      if (level === "firstLevel" && !items.length) {
        this.$refs.section.$refs.childrenSecondLevel.resetSelected();
        this.children.secondLevel = 0;
      }
    },

    setTree(clientType) {
      const { tree } = this.state.generateTree({
        children: {
          firstLevel: this.children.firstLevel,
          secondLevel: this.children.secondLevel,
        },
        points: Math.floor(this.totalPersonalPoints),
        clientType,
        generateType: "discount",
      });

      this.trees[clientType] = this.state.getTreeParams(tree);
    },
    setTrees() {
      this.setTree("ABO");
      this.setTree("VIP");
    },

    finishTree() {
      this.$router.push({ path: "/income" });
    },
  },
};
</script>

<style scoped lang="scss">
.pages-discounts {
  &__container {
    margin: 0 auto;
    width: 100%;
    padding: 85px 36px 24px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
      max-width: 375px;
      padding: 74px 16px 24px;
    }
  }
}
</style>
